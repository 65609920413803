import { Datapoint } from '../../../../model/datapoint/datapoint';
import { DatasetFieldType } from '../../../../model/dataset/dataset-field-type';
import { DatasetField } from '../../../../model/dataset/field/dataset-field';
import { DatapointField } from '../../../../model/datapoint/datapoint-field';
import { NRIUtils } from '../../../../core/tessadata/nri-utils';
import { TessadataNriFields } from '../../../../core/tessadata/tessadata-nri-fields';
import { Functionalities } from '../../../../../environments/app-functionalities';
import { isEnabled } from '../../../../../environments/environment';
import { DatasetFieldScope } from '../../../../model/dataset/dataset-field-scope';
import { TessadataService } from '../../../../data-access-layer/tessadata.service';
import { TessadataFieldsValues } from '../../../../core/tessadata/tessadata-fields-values';
import { TessadataDataset } from '../../../../core/tessadata/tessadata-dataset';
import { TessadataDatasetStructure } from '../../../../core/tessadata/tessadata-dataset-structure';
import { DistanceUnit } from '../../../../constants';
import { ComputationUtils } from '../../../../core/utils/computation-utils';
import { AerisService } from '../../../../data-access-layer/aeris.service';
import { AerisType } from '../../../../core/aeris/AerisType';
import { isObject, isUndefined } from "src/app/core/utils/util-master";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { PoiClosestFilter } from 'src/app/model/datapoint/filter/poi-closest-filter';
import { EChartsOption } from 'echarts';
import { ClimateOverlayPermission, DatapointsClimateChartService } from '../../climate-charts/datapoints-climate-chart.service';
import { LocationProfile } from 'src/app/model/datapoint/location-profile/location-profile';
import { Aeris } from 'src/app/core/utils/aeris';
import { TensorflightKeys, TensorflightStatus } from '../../tensorflight-keys';
import { MaptycsTheme } from '../../climate-charts/custom-theme';
import { MinuchRe } from 'src/app/core/utils/minuch-re';
import { DownloadService } from 'src/app/data-access-layer/download/download.service';
import { Subscription } from 'rxjs';
import { AttachmentUtils } from 'src/app/core/utils/attachment-utils';
import { NotifService } from 'src/app/core/notification/notif.service';

export interface AerisData {
    depth: number;
    text: string;
    value: string;
}

@Component({
    selector: 'map-location-profile',
    templateUrl: './location-profile.component.html',
    styleUrls: ['./location-profile.component.scss', '../datapoints-profile-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationProfileComponent implements OnInit, OnDestroy  {


    _datapoint: Datapoint;
    _dropdownSelectedValue: string;
    @Input() tessadataPOIDatasets: TessadataDataset[] = [];
    @Input() tessadataPOIDatasetsStructures: TessadataDatasetStructure[] = [];

    @Input("datasetFields") datasetFields: { [key: number]: DatasetField };
    @Output() poiClosestSubmittedFilter = new EventEmitter<PoiClosestFilter>();

    datapointFieldsByIds: { [key: string]: DatapointField; } = {};

    nriCards: NRICard[] = [];
    summaryCard: NRICard;
    tessadataEnabled = isEnabled(Functionalities.TESSADATA_DATA);

    tessadataPOIDetails: TessadataFieldsValues[]; //each entry belongs to a new POI
    tessadataPOIDataset: TessadataDataset;
    tessadataPOIDistance: number = 100; //maybe extract this into constants
    tessadataPOIDistanceUnit: DistanceUnit = DistanceUnit.KM;
    tessadataPOISubmittedDistanceUnit: DistanceUnit = DistanceUnit.KM;
    tessadataPOILimit: number = 5; //maybe extract this into constant

    apgFields: DatapointField[];
    externalFields: DatapointField[];
    tensorflightFields: DatapointField[];
    e2valueFields: DatapointField[];
    meteorologicalConditionsData: AerisData[] = [];
    earthquakesData: AerisData[] = [];
    placesData: AerisData[] = [];
    munichReFields: DatapointField[];
    customScoreFields: DatapointField[];

    initMaxTempOption: EChartsOption;
    initMeanTempOption: EChartsOption;
    initMinTempOption: EChartsOption;
    initSnowfallOption: EChartsOption;
    initPrecipitationOption: EChartsOption;
    initSurfaceWindOption: EChartsOption;
    initPopulationDensityOption: EChartsOption;
    initSeaLevelRiseOption: EChartsOption;
    initSeaLevelRiseWarmingOption: EChartsOption;

    locationProfile = new LocationProfile();
    panelOpenState = false;
    tensorflightKeysEnum = TensorflightKeys;
    currentTensorFlightStatus: string;
    tensorflightSuccess = TensorflightStatus.SUCCESS;
    maptycsTheme = MaptycsTheme;

    hazardScores = MinuchRe.HazardScoresElements();
    riskScores = MinuchRe.RiskScoresElements();
    dataSource: any;
    riskDataSource: any;
    private readonly subscriptions: Subscription = new Subscription();
    constructor(
        private tessadataService: TessadataService,
        private aerisService: AerisService,
        private changeDetector: ChangeDetectorRef,
        private datapointsClimateChartService: DatapointsClimateChartService,
        public readonly climateOverlayPermission: ClimateOverlayPermission,
        private readonly downloadService: DownloadService,
        public readonly notifService: NotifService
    
        ) {

        this.initMaxTempOption = datapointsClimateChartService.maxTemperatureService.options;
        this.initMeanTempOption = datapointsClimateChartService.meanTemperatureService.options;
        this.initMinTempOption = datapointsClimateChartService.minTemperatureService.options;
        this.initSnowfallOption = datapointsClimateChartService.snowfallService.options;
        this.initPrecipitationOption = datapointsClimateChartService.precipitationService.options;
        this.initSurfaceWindOption = datapointsClimateChartService.surfaceWindService.options;
        this.initPopulationDensityOption = datapointsClimateChartService.populationDensityService.options;
        this.initSeaLevelRiseOption = datapointsClimateChartService.seaLevelRiseService.options;
        this.initSeaLevelRiseWarmingOption = datapointsClimateChartService.seaLevelRiseWarmingService.options;
    }

    ngOnDestroy(): void {
        this.datapointsClimateChartService.unsubscribeLocationChartInstances();
    }

    onMaxTempChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.maxTemperature.instance = ec;
        }
        this.datapointsClimateChartService.maxTemperatureService.emitLocationChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onMeanTempChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.meanTemperature.instance = ec;
        }
        this.datapointsClimateChartService.meanTemperatureService.emitLocationChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onMinTempChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.minTemperature.instance = ec;
        }
        this.datapointsClimateChartService.minTemperatureService.emitLocationChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSnowfallChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.snowfallTemperature.instance = ec;
        }
        this.datapointsClimateChartService.snowfallService.emitLocationChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onPrecipitationChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.precipitation.instance = ec;
        }
        this.datapointsClimateChartService.precipitationService.emitLocationChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSurfaceWindChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.surfaceWind.instance = ec;
        }
        this.datapointsClimateChartService.surfaceWindService.emitLocationChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onPopulationDensityCharInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.populationDensity.instance = ec;
        }
        this.datapointsClimateChartService.populationDensityService.emitLocationChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSeaLevelRiseChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.seaLevelRise.instance = ec;
        }
        this.datapointsClimateChartService.seaLevelRiseService.emitLocationChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSeaLevelRiseWarmingChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.seaLevelRiseWarming.instance = ec;
        }
        this.datapointsClimateChartService.seaLevelRiseWarmingService.emitLocationChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    ngOnInit(): void {
        this.tessadataPOIDataset = this.tessadataPOIDatasets[0];
        this.fetchClosestPOI();
        this.aerisFetch();
    }

    @Input('datapoint')
    set datapoint(datapoint: Datapoint) {
        if (datapoint) {
            this._datapoint = datapoint;
            this.prepareClimateCharts(this._datapoint);
            datapoint.fields.forEach(field => {
                this.datapointFieldsByIds[field.id] = field;
                field.isPresent = field.numberValue || field.textValue || field.datetimeValue;
                field.isPresent = typeof(field.numberValue) === 'number' && Math.round(field.numberValue) == 0 ? true : field.isPresent;
                field.scope = this.datasetFields[field.id].scope;
            });

            this.externalFields = this._datapoint.fields.filter(field => {
                let datasetField = this.datasetFields[field.id];
                return field.isPresent
                    && (datasetField.scope === DatasetFieldScope.EXTERNAL)
                    && !this.isPrecisely(datasetField)
                    && !this.isTensorFlight(datasetField);
            });
            this.tensorflightFields = this._datapoint.fields.filter(field => {
                let datasetField = this.datasetFields[field.id];
                return field.isPresent
                    && this.isTensorFlight(datasetField);
            });
            this.e2valueFields = this._datapoint.fields.filter(field => {
                let datasetField = this.datasetFields[field.id];
                return field.isPresent
                    && this.isE2Value(datasetField);
            });
            this.apgFields = this._datapoint.fields.filter(field => {
                let datasetField = this.datasetFields[field.id];
                return field.isPresent
                    && (datasetField.scope === DatasetFieldScope.EXTERNAL)
                    && this.isPrecisely(datasetField);
            });
            this.munichReFields = this._datapoint.fields.filter(field => {
                let datasetField = this.datasetFields[field.id];
                return field.isPresent
                    && (datasetField.scope === DatasetFieldScope.MUNICHRE)
                    && this.isMunichRe(datasetField);
            });
            this.customScoreFields = this._datapoint.fields.filter(field => {
                let datasetField = this.datasetFields[field.id];
                return field.isPresent
                    && this.isCustomScore(datasetField);
            });
            this.munichReFields.forEach(field => {
                let datasetField = this.datasetFields[field.id];
                    this.hazardScores.forEach(hazardScore => {
                        if (hazardScore.id === datasetField.id) {
                            hazardScore.values.forEach((item) => {
                                // Iterate over the keys of each object
                                Object.keys(item).forEach((key) => {
                                    const value = item[key];
                                    if(value.value == field.numberValue || (field.numberValue && value.value == field.numberValue.toFixed(4).replace(/\.0+$/,''))) {
                                        value.isActive = true;
                                        value.class = 'progress-bar active';
                                        value.tooltip = value.tooltip + value.value;
                                    } else {
                                        value.tooltip = '';
                                    }
                                });
                            });
                        }
                    });
            });
            this.dataSource = this.hazardScores;
            this.riskDataSource = this.riskScores;
            this.prepareNRICards();
        }
    }

    @Input('dropdownSelectedValue')
    set dropdownSelectedValue(dropdownSelectedValue: string) {
        if (dropdownSelectedValue) {
            this._dropdownSelectedValue = dropdownSelectedValue;
            if (this._dropdownSelectedValue === this.locationProfile.CLIMATE) {
                this.prepareClimateCharts(this._datapoint);
            }
        }
    }

    prepareClimateCharts(_datapoint: Datapoint) {
        this.datapointsClimateChartService.subscribeLocationChartInstances(_datapoint.location, _datapoint.datasetID);
        this.datapointsClimateChartService.checkClimatePermissions(this.climateOverlayPermission, _datapoint.datasetID);
    }

    prepareNRICards() {
        this.summaryCard = this.createNRICard('Summary', 'summary.svg', this.getTessadataSummaryFieldIds(), false);
        this.nriCards.push(this.createNRICard('Avalanche', 'avalanche.svg', [TessadataNriFields.AVALANCHE_RSK, TessadataNriFields.AVALANCHE_EAL, TessadataNriFields.AVALANCHE_HLR]));
        this.nriCards.push(this.createNRICard('Coastal Flooding', 'coastalflooding.svg', [TessadataNriFields.COASTAL_FLOODING_RSK, TessadataNriFields.COASTAL_FLOODING_EAL, TessadataNriFields.COASTAL_FLOODING_HLR]));
        this.nriCards.push(this.createNRICard('Cold Wave', 'coldwave.svg', [TessadataNriFields.COLDWAVE_RSK, TessadataNriFields.COLDWAVE_EAL, TessadataNriFields.COLDWAVE_RSK]));
        this.nriCards.push(this.createNRICard('Drought', 'drought.svg', [TessadataNriFields.DROUGHT_RSK, TessadataNriFields.DROUGHT_EAL, TessadataNriFields.DROUGHT_HLR]));
        this.nriCards.push(this.createNRICard('Earthquake', 'earthquake.svg', [TessadataNriFields.EARTHQUAKE_RSK, TessadataNriFields.EARTHQUAKE_EAL, TessadataNriFields.EARTHQUAKE_HLR]));
        this.nriCards.push(this.createNRICard('Hail', 'hail.svg', [TessadataNriFields.HAIL_RSK, TessadataNriFields.HAIL_EAL, TessadataNriFields.HAIL_HLR]));
        this.nriCards.push(this.createNRICard('Heat Wave', 'heatwave.svg', [TessadataNriFields.HEATWAVE_RSK, TessadataNriFields.HEATWAVE_EAL, TessadataNriFields.HEATWAVE_HLR]));
        this.nriCards.push(this.createNRICard('Hurricane', 'tornado.svg', [TessadataNriFields.HURRICANE_RSK, TessadataNriFields.HURRICANE_EAL, TessadataNriFields.HURRICANE_HLR]));
        this.nriCards.push(this.createNRICard('Ice Storm', 'icestorm.svg', [TessadataNriFields.ICESTORM_RSK, TessadataNriFields.ICESTORM_EAL, TessadataNriFields.ICESTORM_HLR]));
        this.nriCards.push(this.createNRICard('Landslide', 'landslide.svg', [TessadataNriFields.LANDSLIDE_RSK, TessadataNriFields.LANDSLIDE_EAL, TessadataNriFields.LANDSLIDE_HLR]));
        this.nriCards.push(this.createNRICard('Lightning', 'lightning.svg', [TessadataNriFields.LIGHTNING_RSK, TessadataNriFields.LIGHTNING_EAL, TessadataNriFields.LIGHTNING_HLR]));
        this.nriCards.push(this.createNRICard('Riverine Flood', 'river.svg', [TessadataNriFields.RIVERINEFLOOD_RSK, TessadataNriFields.RIVERINEFLOOD_EAL, TessadataNriFields.RIVERINEFLOOD_HLR]));
        this.nriCards.push(this.createNRICard('Strong Wind', 'wind.svg', [TessadataNriFields.STRONGWIND_RSK, TessadataNriFields.STRONGWIND_EAL, TessadataNriFields.STRONGWIND_HLR]));
        this.nriCards.push(this.createNRICard('Tornado', 'tornado.svg', [TessadataNriFields.TORNADO_RSK, TessadataNriFields.TORNADO_EAL, TessadataNriFields.TORNADO_HLR]));
        this.nriCards.push(this.createNRICard('Tsunami', 'tsunami.svg', [TessadataNriFields.TSUNAMI_RSK, TessadataNriFields.TSUNAMI_EAL, TessadataNriFields.TSUNAMI_HLR]));
        this.nriCards.push(this.createNRICard('Volcanic Activity', 'volcanic.svg', [TessadataNriFields.VOLCANIC_RSK, TessadataNriFields.VOLCANIC_EAL, TessadataNriFields.VOLCANIC_HLR]));
        this.nriCards.push(this.createNRICard('Wildfire', 'wildfire.svg', [TessadataNriFields.WILDFIRE_RSK, TessadataNriFields.WILDFIRE_EAL, TessadataNriFields.WILDFIRE_HLR]));
        this.nriCards.push(this.createNRICard('Winter Weather', 'winter.svg', [TessadataNriFields.WINTER_RSK, TessadataNriFields.WINTER_EAL, TessadataNriFields.WINTER_HLR]));
    }

    get DistanceUnit() {
        return DistanceUnit;
    }

    getTessadataSummaryFieldIds() {
        return [TessadataNriFields.SUMMARY_RISK_RATING, TessadataNriFields.SUMMARY_EAL_RATING, TessadataNriFields.SUMMARY_SOVI_RATING, TessadataNriFields.SUMMARY_RESL_RATING];
    }

    createNRICard(title: string, icon: string, fieldIds: string[], removePrefixOfAttributes = true): NRICard {
        let properties: NRIKeyValue[] = [];
        let riskNumericalValues: number[] = [];
        fieldIds.forEach(id => {
            let datasetField = this.datasetFields[id];
            let datapointField = this.datapointFieldsByIds[id];
            if (!datapointField) {
                return;
            }
            let value: any = datapointField.textValue || datapointField.numberValue || datapointField.datetimeValue;
            if (value) {
                riskNumericalValues.push(NRIUtils.getNumericRiskValue(value) || 10);
            }
            let fieldName = datasetField.name;
            let label = removePrefixOfAttributes ? fieldName.substring(fieldName.indexOf('- ') + 2) : fieldName;
            properties.push({ label: label, value: value });
        });
        let maxRisk = Math.min.apply(Math, riskNumericalValues);
        let warnSignColor = NRIUtils.riskValueColorsByNumbers[maxRisk];

        return { title: title, icon: icon, properties: properties, warnSignColor: warnSignColor, visible: maxRisk <= 5 };
    }


    get DatasetFieldType() {
        return DatasetFieldType;
    }


    get DatasetFieldScope() {
        return DatasetFieldScope;
    }

    aerisFetch() {
        this.aerisService.getAerisCredentials().subscribe((value:any) =>{
        let aerisCredentials = Aeris.decodeAerisCredentials(value.aerisClient, value.aerisSecret)
    
        this.aerisService.fetchLocationProfile(AerisType.CONDITIONS, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, this._datapoint.location.y, this._datapoint.location.x).subscribe((value: any) => {
            const response = value.response;
            this.meteorologicalConditionsData = Aeris.computeAerisData(response, '', 0);
            const feildsToBeKeep = ["timestamp", "dateTimeISO", "tempC", "tempF", "feelslikeC", "feelslikeF", "dewpointC", "dewpointF", "humidity", "windDir", "windSpeedKPH", "windSpeedMPH", "windGustKPH", "windGustMPH"];
            this.meteorologicalConditionsData = this.meteorologicalConditionsData.filter(meteorologicalConditions => {
                return (meteorologicalConditions.text !== "" && feildsToBeKeep.includes(meteorologicalConditions.text)) && meteorologicalConditions.value !== "";
            })
        }, () => {
            this.meteorologicalConditionsData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.PLACES, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, this._datapoint.location.y, this._datapoint.location.x).subscribe((value: any) => {
            const response = value.response;
            this.placesData = Aeris.computeAerisData(response, '', 0);
            const feildsToBeKeep = ["state", "stateFull", "country", "countryFull", "region", "regionFull", "elevM", "elevFT", "tz", "tzname", "distanceKM", "distanceMI"];
              this.placesData = this.placesData.filter(places => {
                return places.text !== "" && feildsToBeKeep.includes(places.text) && places.value !== "";
            });
        }, () => {
            this.placesData = [];
        });
    });
    }

    fetchClosestPOI() {
        if (this.tessadataEnabled) {
            let distanceInM = ComputationUtils.getDistanceInMeters(this.tessadataPOIDistance, this.tessadataPOIDistanceUnit);
            this.tessadataPOISubmittedDistanceUnit = this.tessadataPOIDistanceUnit;
            const poiClosestFilter: PoiClosestFilter = {
                latitude: this._datapoint.location.y,
                longitude: this._datapoint.location.x,
                externalDatasetId: this.tessadataPOIDataset.datasetId,
                distanceInMeters: distanceInM,
                distanceUnit: this.tessadataPOIDistanceUnit,
                limit: this.tessadataPOILimit
            };
            this.poiClosestSubmittedFilter.emit(poiClosestFilter);
            this.tessadataService.fetchClosestPOIDetails(poiClosestFilter).subscribe(
                locationDetails => {
                    this.tessadataPOIDetails = locationDetails.datasets;
                    if (this.tessadataPOIDetails.length) {
                        let tessadataDatasetStructure = this.tessadataPOIDatasetsStructures.find(structure => structure.datasetId === this.tessadataPOIDetails[0].datasetId);
                        this.tessadataPOIDetails.forEach(poi => poi.fields.forEach(field => {
                            let tessadataField = tessadataDatasetStructure.fields.find(f => f.fieldId === field.id);
                            field.label = tessadataField.fieldLabel;
                            field.type = tessadataField.fieldType;
                            if (field.label === 'Distance') {
                                field.value = field.value ? String(ComputationUtils.getDistanceInUnit(Number(field.value), this.tessadataPOIDistanceUnit)) : '';
                            }
                        }));
                    }
                    this.changeDetector.detectChanges();
                }
            );
        }
    }

    private isPrecisely(field: DatasetField) {
        return field.displayName.startsWith('Precisely Wildfire') || field.displayName.includes('(APG)');
    }

    private isTensorFlight(field: DatasetField) {
        return field.tags[0] && field.tags[0].toUpperCase() === "TENSORFLIGHT";
    }

    private isE2Value(field: DatasetField) {
        return field.tags[0] && field.tags[0].toUpperCase() === "E2VALUE";
    }

    private isMunichRe(field: DatasetField) {
        return field.id.startsWith('munichre_hazards');
    }

    private isCustomScore(field: DatasetField) {
        return field.tags[0] && field.tags[0].toUpperCase() === "CUSTOM SCORE";
    }

    sidePanelOpenState = true;
    hazardScoresOpenState = true;
    riskScoresOpenState = true;

    displayedColumns: string[] = ['name', 'none', 'low'];

    displayedRiskColumns: string[] = ['name', 'none', 'low'];


    getPointerPosition(element: any): number {
        const field = this._datapoint.fields.find(field => field.id === element.values[1].id);
        if (field !== undefined) {
            return MinuchRe.getPointerPosition(field.numberValue);
        }
        return 0;
    }

    getTooltipText(element: any): string {
        const fieldRiskScore = this._datapoint.fields.find(field => field.id === element.values[0].id);
        const fieldRiskIndex = this._datapoint.fields.find(field => field.id === element.values[1].id);
        if (fieldRiskIndex !== undefined && fieldRiskScore !== undefined) {
            return MinuchRe.getTooltipText(fieldRiskIndex.numberValue, fieldRiskScore.textValue);
        } else if (fieldRiskScore === undefined && fieldRiskIndex !== undefined) {
            return `Risk Score:  - Risk Index: ${fieldRiskIndex.numberValue}`;
        } else if (fieldRiskScore !== undefined && fieldRiskIndex === undefined) {
            return `Risk Score: ${fieldRiskScore.textValue} - Risk Index: `;
        } else {
            const defaultRiskIndex = 0;
            const defaultRiskScore = "Low";
            return `Risk Score: ${defaultRiskScore} - Risk Index: ${defaultRiskIndex}`;
        }
    }

    downloadMunichReport() {
        this.subscriptions.add(
            this.downloadService
                .downloadMunichReReport(this._datapoint.datasetID,this._datapoint.id)
                .subscribe(
                    (response) => {
                        const url =  response.url;
                        window.location.href = response.url;
                    },
                    (error) =>
                        this.notifService.error(
                            "Something went wrong during download"
                        )
                )
        )
    }

    isFieldValueValid(datasetField: DatasetField) {
        let value: any;
        if (this.datasetFields[datasetField.id].baseType === DatasetFieldType.DATE_TIME) {
            value = datasetField.datetimeValue;
        } else if(this.datasetFields[datasetField.id].baseType === DatasetFieldType.NUMBER) {
            value = datasetField.numberValue;
        } else if (this.datasetFields[datasetField.id].baseType === DatasetFieldType.TEXT) {
            value = datasetField.textValue;
        }
        if(value === null || value === undefined || value === "null"){
            return false;
        }
        return true;
    }
}


interface NRICard {
    title: string;
    icon: string;
    properties: NRIKeyValue[];
    warnSignColor: string;
    visible: boolean;
}

interface NRIKeyValue {
    label: string;
    value: string;
}
