<div class="settings-container">
    <form [formGroup]="layerExposeForm">
        <div class="inputs-row">
            <mat-form-field class="cluster-error-fields">
                <mat-select formControlName="equationId" placeholder="Equation" class="cluster-field-dropdown">
                    <ng-container *ngFor="let field of exposeLayerEquations">
                        <mat-option [value]="field.id">{{field.name}} </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="layerExposeForm.get('equationId').errors?.required">
                    Equation is required.
                </mat-error>
            </mat-form-field>
        </div>

        <div class="inputs-row">
            <button mat-button mat-flat-button color="accent" (click)="fetchLayerExposeData()">
                Apply
            </button>
            <button mat-button mat-flat-button color="primary" (click)="clearData()">
                Cancel
            </button>
        </div>
    </form>
</div>

<ng-container *ngIf="showTable; then showTableDatasourceTemplate else noShowMessageTemplate"></ng-container>
<ng-template #showTableDatasourceTemplate>
<div class="report-content">
    <div class="report-table">
        <table mat-table *ngIf="dataSource" [dataSource]="dataSource" class="report-table">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Policy Number </th>
                <td mat-cell *matCellDef="let layerExpose" class="text-left"> {{ layerExpose.policynumber }} </td>
                <td mat-footer-cell *matFooterCellDef> Total </td>
            </ng-container>
            <ng-container matColumnDef="baseValue">
                <th mat-header-cell *matHeaderCellDef> Base Value </th>
                <td mat-cell *matCellDef="let layerExpose" class="text-left"> {{ layerExpose.baseValue | commaFormat }} </td>
                <td mat-footer-cell *matFooterCellDef> {{ layerExposeSummary?.totalSumOfBaseValues || 0 | commaFormat}} </td>
            </ng-container>
            <ng-container matColumnDef="layerValue">
                <th mat-header-cell *matHeaderCellDef> Limit </th>
                <td mat-cell *matCellDef="let layerExpose" class="text-left"> {{ layerExpose.layerLimit | commaFormat }} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="attachmentPoint">
                <th mat-header-cell *matHeaderCellDef> Attachment Point </th>
                <td mat-cell *matCellDef="let layerExpose" class="text-left"> {{ layerExpose.attachmentPoint | commaFormat }} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="exposedValue">
                <th mat-header-cell *matHeaderCellDef> Layer Exposed Value </th>
                <td mat-cell *matCellDef="let layerExpose" class="text-left"> {{ layerExpose.exposedValue | commaFormat }} </td>
                <td mat-footer-cell *matFooterCellDef> {{ layerExposeSummary?.totalSumOfLayerExposure || 0 | commaFormat }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row;columns:displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

    </div>
</div>
</ng-template>

<ng-template #noShowMessageTemplate>
    <p>No data available to display.</p>
</ng-template>

