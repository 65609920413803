<div class="filter-panel-container">
    <div class="boxed-section">
        <div class="filter-action-buttons" style="flex-direction: column">
            <div class="options-button" [matMenuTriggerFor]="actionsMenu">
                <!-- dot-menu-icon -->
                <svg width="13" height="13" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg"
                    style="position: relative;top:2px;">
                    <g fill="#002d57" fill-rule="evenodd">
                        <circle cx="4.5" cy="1" r="1" />
                        <circle cx="4.5" cy="4" r="1" />
                        <circle cx="4.5" cy="7" r="1" />
                    </g>
                </svg>
            </div>
            <mat-menu #actionsMenu="matMenu" class="nested-menu header-menu" backdropClass="mat-menu-header">
                <hr *ngIf="workspaceItemsForFilter.length" class="menu-line-separator" />
                <button *ngIf="workspaceItemsForFilter.length" mat-menu-item (click)="saveFilterConfiguration(true)"
                    style="margin-bottom: 0px">
                    <span>
                        <!-- save-icon -->
                        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                <path
                                    d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                    stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                    stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                    d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                <path d="M0 0h24.01v24.01H0z" />
                            </g>
                        </svg>
                    </span>
                    Save
                </button>
                <hr class="menu-line-separator" />
                <button mat-menu-item (click)="saveFilterConfiguration(false)" style="margin-bottom: 0px">
                    <span>
                        <!-- save-icon -->
                        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                <path
                                    d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                    stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                    stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                    d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                <path d="M0 0h24.01v24.01H0z" />
                            </g>
                        </svg>
                    </span>
                    Save as
                </button>
                <hr class="menu-line-separator" />
                <button mat-menu-item (click)="removeFilterFromMap()" style="margin-bottom: 0px">
                    <span>
                        <!-- undo-icon -->
                        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                            style="margin-right: 4px;">
                            <g fill="none" fill-rule="evenodd">
                                <g stroke-linecap="round" stroke-linejoin="round" stroke="#0044f1" stroke-width="1.5">
                                    <path d="M8 5 5 8l3 3" />
                                    <path d="M5 8h9c3.314 0 6 2.462 6 5.5h0c0 3.038-2.686 5.5-6 5.5H6" />
                                </g>
                                <path d="M0 0h24v24H0z" />
                            </g>
                        </svg>
                    </span>
                    Remove
                </button>
                <hr class="menu-line-separator" />
                <button mat-menu-item (click)="openData = !openData;$event.stopPropagation()"
                    style="display: flex; justify-content: space-between; margin-bottom:0px">
                    <div>
                        <span>
                            <mat-icon fontIcon="fa-binoculars" fontSet="fas" style="color: #0044f1;"></mat-icon>
                        </span>
                        Open
                    </div>
                    <button class="workspace-item-menu-button icon-menu-button justify-end"
                        (click)="openData = !openData;$event.stopPropagation()">
                        <mat-icon style="color: #0044f1; font-size: 16px;">
                            {{ openData ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </button>

                <ul *ngIf="openData" class="nested-menu" style="margin: 0px">
                    <ng-container *ngFor="let savedFilterConfiguration of workspaceItemsForFilter">
                        <hr class="menu-line-separator" />
                        <li class="mat-tree-node" style="width: 100%; min-height: 25px !important;">
                            <button style="flex: 1; justify-content: space-between; margin-bottom: 0px;" mat-menu-item
                                (click)="applyWorkspaceItemFilter(savedFilterConfiguration)"
                                class="workspace-item-menu-option"
                                [matTooltip]="savedFilterConfiguration.name.length > 20 ? savedFilterConfiguration.name : ''"
                                matTooltipPosition="left">

                                {{ truncateName(savedFilterConfiguration.name) }}

                                <button *ngIf="savedFilterConfiguration.isDeletable"
                                    class="workspace-item-menu-button icon-menu-button"
                                    (click)="deleteWorkspaceItem(savedFilterConfiguration); $event.stopPropagation()">
                                    <svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <path
                                                d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" class="icon-actions" />
                                            <path d="M0 0h24v24H0z" />
                                        </g>
                                    </svg>
                                </button>
                            </button>
                        </li>
                    </ng-container>
                </ul>
            </mat-menu>
        </div>
        <div class="global-filter-tree-container">
            <ng-container *ngIf="dataSource.data[0]?.children.length > 0">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="sub-menu-options-child">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="sub-menu-form-options-tree"
                        style="flex-direction: column !important;">
                        <ul style="padding: 0px; margin: 0px; width: 100%">
                            <li class="mat-tree-node sub-menu-form-options" style="padding: 8px 0 !important;"
                                *ngIf="node?.geometryType === DatasetGeometryType.POINT || node.params?.overlay?.geometryType === DatasetGeometryType.POINT && node.params?.key == 0">
                                <div class="nested-distance-filter-container">
                                    <div class="nested-distance-filter" style="margin: 8px 0;">
                                        <!-- Added (input) event, whenever the value changes -->
                                        <input class="link-distance-input" #myInput placeholder="Distance"
                                            type="number"
                                            [(ngModel)]="overlaysFilterDistance[node.params?.overlay?.id]"
                                            (input)="onDistanceInputChange(node.params?.overlay?.id, myInput.value, node)"
                                            (keydown)="validateInputKeys($event)"
                                            >
                                        <mat-select #distanceUnit [(value)]="defaultDistanceUnit"
                                            class="location-address-select">
                                            <mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM}}</mat-option>
                                            <mat-option [value]="DistanceUnit.MILES">{{DistanceUnit.MILES}}</mat-option>
                                        </mat-select>
                                    </div>
                                  <div style="display: flex; gap: 8px;">
                                    <button class="apply-button-wrapper"
                                        (click)="distanceFilter(node.params?.overlay, myInput.value, distanceUnit.value)"
                                        style="width: 50%">
                                        <span class="apply-distance-btn">Apply</span>
                                    </button>
                                    <button mat-button mat-flat-button color="primary"
                                        (click)="resetFilter(node)"
                                        style="width: 50%">
                                        <span class="apply-distance-btn">Reset</span>
                                    </button>
                                  </div>
                                </div>
                            </li>
                            <li class="mat-tree-node sub-menu-checkbox-options"
                                *ngIf="node.params?.overlay?.geometryType === DatasetGeometryType.POINT && node.params?.showCheckBox">
                                <button mat-icon-button disabled></button>
                                <!-- <mat-checkbox [disabled]="!overlaysFilterDistance[node.params?.overlay?.id]" Code commented for any future references MS-1682 -->
                                <mat-checkbox [disabled]="!isCheckboxEnabled(node.params?.overlay?.id)"
                                    class="checklist-leaf-node mat-checkbox-label-custom"
                                    (ngModelChange)="callFilterAction(node.selected, node)"
                                    [(ngModel)]="node.selected">{{getDisplayName(node)}}</mat-checkbox>
                            </li>

                            <li class="mat-tree-node sub-menu-checkbox-options child-inner-tooltip"
                                matTooltipPosition="right"
                                *ngIf="node.params?.overlay?.geometryType !== DatasetGeometryType.POINT && node.params?.showCheckBox">
                                <button mat-icon-button disabled></button>
                                <mat-checkbox class="checklist-leaf-node mat-checkbox-label-custom"
                                    (ngModelChange)="callFilterAction(node.selected, node)"
                                    [(ngModel)]="node.selected">{{getDisplayName(node)}}</mat-checkbox>
                            </li>
                        </ul>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: filterTreeStrcuture.hasChild"
                        class="sub-menu-options-parent external-sub-menu">
                        <ul style="padding: 0px; margin: 0px">
                            <li class="global-filter">
                                <hr class="menu-line-separator" />
                                <div class="mat-tree-node"
                                    style="display: flex !important; flex-direction: row !important; justify-content: space-between !important;">

                                    <span class="savedFilterConfiguration-inner-parent">{{node.name}}</span>
                                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                        <mat-icon class="mat-icon-rtl-mirror">
                                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                        </mat-icon>
                                    </button>
                                </div>
                                <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                                    <ng-container matTreeNodeOutlet></ng-container>
                                </ul>
                            </li>
                        </ul>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>
        </div>
    </div>
</div>


<app-workspace-item-dialog #workspaceItemDialog [accountId]="account?.id" [datasetId]="dataset?.id"
    (itemSubmitted)="refreshWorkspaceItems($event)"></app-workspace-item-dialog>
