import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { tap } from "rxjs/operators";
import { DatasetType } from "../custom-scoring.component";
import { CustomScoringService } from "./custom-scoring.service";
import { CustomScoring } from "../model/custom-service";

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    private cacheExpiration;
    private cacheKey;

    constructor(
        private http: HttpClient,
        private customService: CustomScoringService
    ) {
        this.customService = customService;
    }

    getData(cacheDurationInMinutes: number, cacheKey: string, accountID: number): Observable<any> {
        
        const cachedData = this.getCache(cacheKey);
        
        
        
        const cacheExpiration = this.getCacheExpiration();

        const isCacheValid = cacheExpiration && new Date().getTime() < cacheExpiration;

        if (cachedData && isCacheValid) {         
            return of(cachedData);
        } else {
        }
    }

    private getCache(cacheKey: string): any {
        const data = localStorage.getItem(this.cacheKey);
        let parsedData = JSON.parse(data)
        
        return data ? JSON.parse(data) : null;
    }

    private getCacheExpiration(): number | null {
        const expiration = localStorage.getItem(this.cacheExpiration);
        return expiration ? parseInt(expiration, 10) : null;
    }

    private setCache(data: any, cacheDurationInMinutes: number): void {
        const expirationTime = new Date().getTime() + cacheDurationInMinutes * 60 * 1000;
        let stringifyData = JSON.stringify(data);
        
        localStorage.setItem(this.cacheKey, JSON.stringify(data));
        localStorage.setItem(this.cacheExpiration, expirationTime.toString());
    }
}