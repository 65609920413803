export interface CustomScoring{
    id : string
    name : string
    fieldName : string
    accountId : number
    applied : boolean
    deleted : boolean
    scope : CustomScoringScope
    scoring : scoring
}

export enum CustomScoringScope {
    INTERNAL="INTERNAL", 
    EXTERNAL = "EXTERNAL", 
    GLOBAL = "GLOBAL"
}

export interface scoring{
    textValues : string[]
    minNumberValue : number
    maxNumberValue : number
    label : string
    order : number
}