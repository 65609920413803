import { Injectable } from '@angular/core';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Constants } from '../../constants';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { NotifService } from '../../core/notification/notif.service';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptorService {

    constructor(
        private readonly notifService: NotifService,
        private readonly authService: AuthService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.body && event.body.error && event.status !== 200) {
                        this.notifService.error(JSON.stringify(event.body.error));
                        throw (event.body.error);
                    }
                }
            }, (err: any) => {

                if (err instanceof HttpErrorResponse) {
                    console.log(err);
                    if (err.status === Constants.AUTH_ERROR_CODE) {
                        if (this.isAerisApi(request.url)) {
                            console.log('Aeris Authentication...');
                        } else {
                            this.notifService.error('Your session has expired. Please re-login', 'Session expired');
                            this.authService.logout();
                        }
                    } else if (err.status === Constants.FORBIDDEN_ERROR_CODE) {
                        this.notifService.error(err.error.message);
                    } else if (err.status === Constants.TOO_MANY_REQUESTS) {
                        this.notifService.error('Too many requests, please try again after sometime')
                    } else if (err.status !== Constants.BUSINESS_ERROR_CODE && !err.error.errorId) {
                        this.notifService.error('mmm... something went wrong. Please come back later.');
                    } else {
                        this.notifService.error(err.error.message);
                    }
                }
            }));
    }

    private isAerisApi(url: string): boolean {
        const aerieApiDomain = "aerisapi.com"
        const urlObject = new URL(url);
        return urlObject.hostname.includes(aerieApiDomain);
    }
}
