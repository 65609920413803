<div class="form-container">
    <form *ngIf="createAccountForm" [formGroup]="createAccountForm">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" name="account-name" autocomplete="off" [disabled]="isFormDisabled" [ngClass]="{'disabled-style': isFormDisabled}"/>
            <mat-error *ngIf="createAccountForm.controls.name.hasError('used') ">
                Layer Exposed with this name already exists
            </mat-error>
            <mat-error *ngIf="createAccountForm.controls.name.hasError('required') && createAccountForm.get('name').invalid && (createAccountForm.get('name').dirty || createAccountForm.get('name').touched)">
                Layer Exposed name is required
            </mat-error>
            <mat-error
                *ngIf="createAccountForm.controls['name'].touched && createAccountForm.controls['name'].hasError('maxlength')">
                You exceed the maximum number of characters.
            </mat-error>
            <mat-error
                *ngIf="createAccountForm.controls['name'].touched && createAccountForm.controls['name'].hasError('pattern')">
                You cannot use special characters. Space is allowed only between words.
            </mat-error>
        </mat-form-field>

        <!-- -------Base Value--------  (selectionChange)="onFieldTypeChange($event)" -->

        <mat-form-field>
            <mat-select [ngClass]="{'disabled-style': isFormDisabled}" [disabled]="isFormDisabled" formControlName="baseValue" placeholder="Select Base Value" style="font-size: 12px; color: black !important;">
              <mat-option *ngFor="let data of locationDatasetDropdown" [value]="data.id">
                {{ data.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createAccountForm.controls.baseValue.hasError('required') && createAccountForm.get('baseValue').invalid && (createAccountForm.get('baseValue').dirty || createAccountForm.get('baseValue').touched)">
              Base Value is required.
            </mat-error>
          </mat-form-field>


            <!-- Limit  -->

            <mat-form-field>
                <mat-select [ngClass]="{'disabled-style': isFormDisabled}" [disabled]="isFormDisabled" formControlName="limit" placeholder="Select Limit" style="font-size: 12px; color: black !important;">
                  <mat-option *ngFor="let data of policyDatasetDropdown" [value]="data.id">
                    {{ data.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="createAccountForm.controls.limit.hasError('required') && createAccountForm.get('limit').invalid && (createAccountForm.get('limit').dirty || createAccountForm.get('limit').touched)">
                  Limit is required.
                </mat-error>
              </mat-form-field>
              
              <!-- Attachment Point -->
              <mat-form-field>
                <mat-select [ngClass]="{'disabled-style': isFormDisabled}" [disabled]="isFormDisabled" formControlName="attachmentPoint" placeholder="Select Attachment Point" style="font-size: 12px; color: black !important;">
                  <mat-option *ngFor="let data of policyDatasetDropdown" [value]="data.id">
                    {{ data.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="createAccountForm.controls.attachmentPoint.hasError('required') && createAccountForm.get('attachmentPoint').invalid && (createAccountForm.get('attachmentPoint').dirty || createAccountForm.get('attachmentPoint').touched)">
                    Attachment Point is required.
                </mat-error>
              </mat-form-field>
              <!--  -->


        <br>
        <br>
        <div *ngIf="true" style="display: flex; gap: 10px; align-items: center;justify-content: space-evenly;">
            <button [ngClass]="{'disabled-style': isFormDisabled}" mat-flat-button color="accent" style="border-radius: 5px;padding:0px 230px;font-size: 43px;" (click)="submitLayerExpose()" [disabled]="isFormDisabled">Save
            </button>
        </div>

    </form>

</div>
