import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../data-access-layer/account/account.service';
import {ActivatedRoute} from '@angular/router';
import {Account} from '../../model/account/account';
import {Dataset, UpdateDatasetRequest} from '../../model/dataset/dataset';
import {AccountApplicationBasicFormControlsConfig, AccountApplicationFormControlsConfig, DefaultField} from './fields.constants';
import {DatasetService} from '../../data-access-layer/dataset/dataset.service';
import {NotifService} from '../../core/notification/notif.service';
import {MaptycsApplication} from '../../model/account/maptycs-application';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DatapointsPageStateService} from '../../dataset/datapoints/datapoints-page-state.service';
import {getLinks} from '../../account/constants';
import {DatasetType} from '../../model/dataset/dataset-type';
import {RenderingErrorCodes} from '../../model/dataset/rendering/rendering-error-codes';
import {checkIntervalsValidity} from '../../shared/validators/colorization/colorization.validator';
import { GroupService } from 'src/app/data-access-layer/groups/group.service';
import { isNull, isUndefined } from "src/app/core/utils/util-master";
import { Group } from 'src/app/model/group/group';
import { groupTypes } from 'src/app/account/account-groups/account-create-groups-panel/account-create-groups-panel.constants';
import { UserStateService } from 'src/app/auth/user-state-service';
import { ShowInMap } from 'src/app/model/dataset/dataset-config-filter.enum';

@Component({
    selector: 'map-fields',
    templateUrl: './account-fields.component.html',
    styleUrls: ['./account-fields.component.scss']
})
export class AccountFieldsComponent implements OnInit {
    headerLinks = getLinks(this.constructor.name);
    breadCrumbs = ['Home', 'Accounts', 'Settings'];
    account: Account;
    applications: { name: string, id: string }[];
    dataset: Dataset;
    applicationId: string;
    editOverlayForm: UntypedFormGroup;
    displayColorizationBox: boolean;
    groupsByIds: Map<number, Group> = new Map();
    groupes: {rowGroups: Group[], columnGroups: Group[]};

    constructor(
        private readonly accountService: AccountService,
        private readonly datasetService: DatasetService,
        private readonly notifService: NotifService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly route: ActivatedRoute,
        private readonly groupService: GroupService,
        private readonly userStateService: UserStateService
    ) {
        this.applications = [];
        this.dataset = {fields: []} as Dataset;
        this.applicationId = '';
    }

    /**
     * Get current account information.
     */
    ngOnInit(): void {
        const accountId = +this.route.snapshot.paramMap.get('accountId');
        if (accountId) {
            this.accountService.findAccount(accountId)
                .subscribe(account => {
                    this.extractAndInitApplications(account)});
        }
    }

    get DatasetType() {
        return DatasetType.ACCOUNT_OVERLAY;
    }

    /**
     * Extract the applications array for the toggle button.
     * Trigger rest call for updating the current dataset.
     */
    extractAndInitApplications(account: Account): void {
        this.account = account;
        this.datapointsPageStateService.activeAccount = account;

        account.datasets.forEach(dataset => {
            this.applications.push({
                name: dataset.name,
                id: dataset.id
            });
        });

        this.applicationId = this.applications[0].id;

        this.getCurrentDataset(this.applicationId);
    }

    get componentName() {
        return this.constructor.name;
    }


    getCurrentDataset(datasetId: string): void {
        this.applicationId = datasetId;
        this.datasetService.getDataset(datasetId).subscribe(dataset => {
            this.dataset = dataset;
            this.fetchGroups(dataset.id);
            this.displayColorizationBox = this.dataset.application === MaptycsApplication.LOCATIONS || this.dataset.application === MaptycsApplication.BCS_LOCATIONS;
            if (this.displayColorizationBox) { // policies and claims don't have styling options
                this.dataset.stylingOptions.colors = this.dataset.stylingOptions.colors || [];
            }
            this.initFormGroup();
        });
    }

    addNewField(): void {
        this.dataset.fields.push(new DefaultField());
    }

    saveDataset(): void {
        let isEmptyField = false;
        this.dataset.fields.forEach(field => {
            if(field.name === '') {
                isEmptyField = true;
            }
        });
        if (isEmptyField) {
            this.notifService.error('Please fill in Name');
            return;
        }
        let  invalidAttribute = '';
        if (this.dataset.id !== null) {
            invalidAttribute = checkIntervalsValidity(this.dataset, this.dataset.stylingOptions.intervalOptions);
            if (!invalidAttribute) {
                let request: UpdateDatasetRequest = {
                    id: this.dataset.id,
                    version: this.dataset.version,
                    fields: this.dataset.fields,
                    name: this.editOverlayForm.controls.name.value,
                    isLinkable: this.editOverlayForm.controls.isLinkable.value,
                    hasHazardHubEnabled: this.editOverlayForm.controls.hasHazardHubEnabled.value,
                    thematicMapSettings: this.editOverlayForm.controls.thematicMapSettings.value,
                    mainSummaryFieldIndex: this.editOverlayForm.controls.mainSummaryFieldIndex.value,
                    stylingOptions: this.dataset.stylingOptions,
                    showIn: this?.editOverlayForm?.controls?.showIn?.value ? this?.editOverlayForm?.controls?.showIn?.value : ShowInMap.Filter_Panel
                };
                this.datasetService.updateDataset(request).subscribe(updatedDataset => {
                    this.getCurrentDataset(this.applicationId);
                    this.notifService.success('The dataset was successfully updated');
                    localStorage.removeItem('locationCustomFields_' + this.account.id);
                }, error => {
                    this.notifService.error('Not updated... You might named fields exactly the same. Please check.');
                });
            } else {
                switch (invalidAttribute) {
                    case RenderingErrorCodes.COLORS:
                        this.notifService.error('You must select some colors for the colorization');
                        return;
                    case RenderingErrorCodes.FIELD:
                        this.notifService.error('You must choose a field for the colorization');
                        return;
                    case RenderingErrorCodes.INTERVALS:
                        this.notifService.error('You must define at least one interval');
                        return;
                    case RenderingErrorCodes.INTERVALS_OVERLAP:
                        this.notifService.error('The defined intervals must not overlap');
                        return;
                    case RenderingErrorCodes.DUPLICATE_INTERVAL:
                        this.notifService.error('The defined intervals must not have duplicates');
                        return;
                }
            }
        }
    }

    initFormGroup() {
        if (this.displayColorizationBox) {
            this.editOverlayForm = this.formBuilder.group(new AccountApplicationFormControlsConfig(this.formBuilder, this.dataset));
        } else {
            this.editOverlayForm = this.formBuilder.group(new AccountApplicationBasicFormControlsConfig(this.formBuilder, this.dataset));
        }
        // this.editOverlayForm.controls.type.setValue(DatasetType.ACCOUNT_OVERLAY);
    }

    get MaptycsApplication() {
        return MaptycsApplication;
    }

    fetchGroups(datasetID: string): void {
        if (!isNull(this.dataset.accountID) && isUndefined(this.groupes)) {
            let defaultGroupValue: Group[]  = [{ id: null, createdOn: 0, updatedOn: 0, parentId: 0, accountID: 0, datasetID: '0', application: 'ABC', isChecked: false, name: 'None' } as Group];
            this.groupService.getGroups(datasetID, this.dataset.accountID).subscribe((groups) => {
                const rowGroups = groups.filter(element => element.type == groupTypes.ROW_GROUP);
                groups = [...defaultGroupValue, ...groups.filter(element => element.type == groupTypes.COLUMN_GROUP)];
                groups.forEach(group => this.groupsByIds.set(group.id, group));
                this.groupes = {rowGroups: rowGroups, columnGroups: groups};
            }, err => {
                console.log(err);
            });
        }
    }


}
