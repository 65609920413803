import {Injectable} from '@angular/core';
import {Dataset} from '../../model/dataset/dataset';
import {Account} from '../../model/account/account';
import {ImageOverlay} from '../../model/overlay/external/image-overlay';
import {MapInteractionMode} from './map-interaction-mode';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {DatasetField} from '../../model/dataset/field/dataset-field';
import {DatasetGeometryType} from '../../model/dataset/dataset-geometry-type';
import {ObjectUtils} from '../../core/utils/object-utils';
import {DatasetFieldScope} from '../../model/dataset/dataset-field-scope';
import {MapInteractionStatus} from './map-interaction-status';
import {defaultMapStatus} from '../../core/map/map.constants';

/**
 * This is a service that stores everything related to the active context of a datapoints page (table or map).
 * On leave page we should clean this up.
 */
@Injectable()
export class DatapointsPageStateService {

    datasetsByIds: Map<string, Dataset> = new Map(); // all datasets related to an account (including account applications). This is for fast accessing.
    activeDatasetsOnMap: Dataset[] = []; // only the map component should touch this
    activeImageOverlaysOnMap: ImageOverlay[] = []; // only the map component should touch this
    activeGroups: number[];
    activeAccountId: number; // we can have this sooner than the actual account
    activeAccount: Account;
    private activeDataset: Dataset;
    private activeDatasetFields: Map<string, DatasetField> = new Map();
    private activateDatasetTessadataFields: { nriFields: DatasetField[], externalFields: DatasetField[], tensorflightFields: DatasetField[], e2valueFields: DatasetField[], munichreFields: DatasetField[], customScoreFields:DatasetField[] } = {
        nriFields: [],
        externalFields: [],
        tensorflightFields: [],
        e2valueFields:[],
        munichreFields: [],
        customScoreFields: []
    };
    accountOverlays: Dataset[] = []; // private + global
    accountDatasets: Dataset[] = [];
    private linkedAccountOverlays: Dataset[] = []; // overlays that are linked and assigned to the active dataset
    private linkedAccountDatasets: Dataset[] = []; // account application datasets that are linked to the active dataset
    private filterAccountDatasets: Dataset[]; // account application datasets that are available for filtering
    private mapStatus: MapInteractionStatus = {mode:MapInteractionMode.DEFAULT}; // if the default, thematic map, or flood map are ON.

    private updateMapModeSubject: Subject<MapInteractionStatus> = new BehaviorSubject({mode:MapInteractionMode.DEFAULT});

    getDataset(id: string): Dataset {
        return this.datasetsByIds.get(id);
    }

    setActiveDataset(dataset: Dataset) {
        this.activeDataset = dataset;
        if (dataset.geometryType === DatasetGeometryType.POINT) { // we have locations
            dataset.fields.forEach(field => {
                this.activeDatasetFields.set(field.id, field);
                if (field.isGenerated) {
                    if (field.scope === DatasetFieldScope.EXTERNAL) {
                        this.activateDatasetTessadataFields.externalFields.push(field);
                    } else if (field.scope === DatasetFieldScope.NRI) {
                        this.activateDatasetTessadataFields.nriFields.push(field);
                    } else if (field.scope === DatasetFieldScope.TENSORFLIGHT) {
                        field.selected = false;
                        this.activateDatasetTessadataFields.tensorflightFields.push(field);
                    }else if (field.scope === DatasetFieldScope.E2VALUE) {
                        field.selected = false;
                        this.activateDatasetTessadataFields.e2valueFields.push(field);
                    } else if (field.scope === DatasetFieldScope.MUNICHRE) {
                        field.selected = false;
                        this.activateDatasetTessadataFields.munichreFields.push(field);
                    } else if(field.scope === DatasetFieldScope.CUSTOM_SCORE){
                        field.selected = false;
                        this.activateDatasetTessadataFields.customScoreFields.push(field);
                    }
                }
            });
        }
    }

    getActiveDataset() {
        return this.activeDataset;
    }

    /**
     * This method will return a clone.
     */
    getLinkedAccountDatasets() {
        return ObjectUtils.clone(this.linkedAccountDatasets);
    }

    getFilterAccountDatasetsCloned(): Dataset[] {
        return ObjectUtils.clone(this.filterAccountDatasets);
    }

    getFilterAccountDatasets(): Dataset[] {
        return this.filterAccountDatasets;
    }

    setFilterAccountDatasets(value: Dataset[]) {
        this.filterAccountDatasets = value;
    }

    /**
     * This method will return a clone.
     */
    getLinkedAccountOverlays() {
        return ObjectUtils.clone(this.linkedAccountOverlays);
    }

    getLinkedAccountDatasetsNotCloned() {
        return this.linkedAccountDatasets;
    }

    getLinkedAccountOverlaysNotCloned() {
        return this.linkedAccountOverlays;
    }


    setLinkedAccountOverlays(value: Dataset[]): void {
        this.linkedAccountOverlays = value;
    }

    setLinkedAccountDatasets(value: Dataset[]) {
        this.linkedAccountDatasets = value;
    }


    getActiveTessadataFields() {
        return this.activateDatasetTessadataFields;
    }

    storeDataset(dataset: Dataset) {
        this.datasetsByIds.set(dataset.id, dataset);
    }

    reset() {
        this.datasetsByIds = new Map();
        this.activeDatasetFields = new Map();
        this.activeDatasetsOnMap = [];
        this.mapStatus = defaultMapStatus;
        this.updateMapModeSubject = new BehaviorSubject(defaultMapStatus);
        this.activeDataset = null;
        this.activateDatasetTessadataFields = {nriFields: [], externalFields: [], tensorflightFields: [], e2valueFields: [], munichreFields: [], customScoreFields:[] };
    }

    updateMapMode(newState: MapInteractionStatus) {
        this.mapStatus = newState;
        this.updateMapModeSubject.next(newState);
    }

    onMapInteractionChange(): Observable<MapInteractionStatus> {
        return this.updateMapModeSubject;
    }

    getMapInteractionStatus() {
        return this.mapStatus;
    }


    getActiveDatasetFields(): Map<string, DatasetField> {
        return this.activeDatasetFields;
    }
}
