import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomScoring, CustomScoringScope } from "../model/custom-service";
import { SortController } from "ag-grid-community";

@Injectable({
    providedIn: "root",
})
export class CustomScoringService{
    constructor(private readonly http: HttpClient) {}
    private baseDomain = "/custom-scoring";

    getCustomScoringsForAccount(accountID : number, scope : CustomScoringScope, datasetID? :string){
        let params = new HttpParams().set('scope',scope);
        if(datasetID) {
            params.append('datasetID',datasetID);
        }
         let url = this.baseDomain+`/${accountID}?scope=${scope}`;
         if (datasetID) {
            url += `&datasetID=${datasetID}`;
         }
        return this.http.get<CustomScoring[]>(
            url
        );
    }

    getLocationCustomScoringFields(datasetId){
        return this.http.get<CustomScoring[]>(
            this.baseDomain+`/fields/${datasetId}`
        );
    }

    createCustomScoring(accountID: any, payload) {
        return this.http.post<void>(`/custom-scoring/${accountID}/save`, payload);
    }

    deleteCustomScoring(accountID: any, customScoringId) {
        return this.http.delete<boolean>(`/custom-scoring/${accountID}/delete/${customScoringId}`);
    }

    updateAppliedFlag(accountID, customScoringID, payload) {
        return this.http.put<boolean>(`/custom-scoring/${accountID}/update/${customScoringID}`, payload);
    }

    getCustomScoring(accountID, customScoringID) {
        return this.http.get<CustomScoring>(`/custom-scoring/${accountID}/scoring/${customScoringID}`);
    }

    updateCustomScoring(accountID: any, payload) {
        return this.http.put<void>(`/custom-scoring/${accountID}/update`, payload);
    }

    applyFlag(accountID, customScoringID, applied_flag) {
        return this.http.post<boolean>(`/custom-scoring/${accountID}/apply/${customScoringID}/${applied_flag}`,{});
    }
}