import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DatapointFilter} from '../../model/datapoint/filter/datapoint-filter';
import {LayerExposedService} from '../../account/layer-exposed/services/layer-exposed.service';
import {ActivatedRoute} from '@angular/router';
import {DatapointsFilterService} from '../../dataset/datapoints/datapoints-filter.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'map-layer-expose-accumulation',
    templateUrl: './layer-expose-accumulation.component.html',
    styleUrls: ['./layer-expose-accumulation.component.scss']
})
export class LayerExposeAccumulationComponent {
    filter: DatapointFilter;
    layerExposeForm: UntypedFormGroup;
    exposeLayerEquations: any;
    showError = false;
    accountId: number;
    layerExposeSummary: any;
    dataSource: any;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    showTable: boolean = false;
    displayedColumns: string[] = ['name', 'exposedValue', 'baseValue', 'layerValue', 'attachmentPoint'];

    constructor(
        private layerExposeService: LayerExposedService,
        private readonly route: ActivatedRoute,
        private datapointFilterService: DatapointsFilterService,
        private readonly changeDetector: ChangeDetectorRef,
    ) {
        this.exposeLayerEquations = [];
        this.layerExposeForm = new UntypedFormGroup({});
        this.filter = datapointFilterService.getActiveFilter();
    }

    ngOnInit() {
        this.accountId = +this.route.snapshot.paramMap.get('accountId');
        this.layerExposeForm = new UntypedFormGroup({
            equationId: new FormControl('', [Validators.required]),
        });

        this.layerExposeService.getAppliedLayerExpose(this.accountId)
            .subscribe(equations => {
                this.exposeLayerEquations = equations;
            });

            this.datapointFilterService.onFilterChange().pipe(debounceTime(200)).subscribe(newFilter => {
                this.filter = newFilter;
                this.fetchLayerExposeData();
            });
    }

    get equationIdControl(): UntypedFormControl {
        return this.layerExposeForm.get('equationId') as UntypedFormControl;
    }

    fetchLayerExposeData(): void {
        if (!this.equationIdControl.valid) {
            this.showError = true;
            return;
        }
        this.filter = this.datapointFilterService.getActiveFilter();
        const selectedEquation = this.layerExposeForm.get('equationId')?.value;
        this.layerExposeService.getLayerSummaryResponse(this.filter, this.accountId, selectedEquation)
            .subscribe(data => {
                this.showTable = true;
                this.layerExposeSummary = data;
                this.dataSource = new MatTableDataSource<any>(this.layerExposeSummary.exposureResponses);
                this.dataSource.sort = this.sort;
                this.changeDetector.detectChanges();
            });
    }

    clearData() {
        this.showTable = false;
        this.equationIdControl.setValue(null);
        this.showError = false;
    }
}
