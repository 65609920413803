import {
    ChangeDetectorRef,
    Component,
    Input,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Dataset } from "src/app/model/dataset/dataset";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { WorkspaceItemType } from "../../../model/workspace/workspace-item-type";
import { DatapointFilter } from "src/app/model/datapoint/filter/datapoint-filter";
import { Account } from "src/app/model/account/account";
import { DatasetGeometryType } from "src/app/model/dataset/dataset-geometry-type";
import { isUndefined } from "src/app/core/utils/util-master";
import { DistanceUnit } from "src/app/constants";
import { DatapointsFilterService } from "../datapoints-filter.service";
import { MapStateService } from "src/app/map/map-state.service";
import { WorkspaceItemDialogComponent } from "../../workspace-item/projection/workspace-item-dialog.component";
import { WorkspaceItemService } from "src/app/data-access-layer/workspace-item/workspace-item.service";
import { UserStateService } from "src/app/auth/user-state-service";
import { WorkspaceItemAccess } from "src/app/model/workspace/workspace-item-access";
import { debounceTime } from "rxjs/operators";
import { NotifService } from "src/app/core/notification/notif.service";

@Component({
    selector: "map-global-filter",
    templateUrl: "./global-filter.component.html",
    styleUrls: ["./global-filter.component.scss"],
})
export class GlobalFilterComponent {
    @ViewChild("workspaceItemDialog", { static: true })
    workspaceItemDialog: WorkspaceItemDialogComponent;

    @Input() dataset: Dataset;
    @Input() account: Account;
    @Input() filter: DatapointFilter;
    @Input() workspaceItemsForFilter: WorkspaceItem[];
    @Input() dataSource: any;
    @Input() filterTreeStrcuture: any;
    @Input() treeControl: any;
    selectedItems: any[] = [];

    get DistanceUnit() {
        return DistanceUnit;
    }
    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    get WorkspaceItemType() {
        return WorkspaceItemType;
    }
    enabledCheckboxes: Set<string> = new Set(); 
    overlaysFilterDistance = {};
    defaultDistanceUnit: DistanceUnit = DistanceUnit.KM;
    openData: boolean = false;

    constructor(
        public readonly dialog: MatDialog,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly workspaceItemsService: WorkspaceItemService,
        readonly userStateService: UserStateService,
        private cd: ChangeDetectorRef,
        private readonly notifService: NotifService,
    ) {}

    ngOnInit() {
        this.deselectFilterNodeWhenRemovedFromFilterBar();
        this.updateFilterItemsWhenChanged();
        this.selectMenuItemsWhenFilterUpdated();
    }

    selectMenuItemsWhenFilterUpdated() {
        this.datapointsFilterService
            .onSavedFilterApplied()
            .pipe(debounceTime(200))
            .subscribe(() => {
                const activeFilter =
                    this.datapointsFilterService.getActiveFilter();
                this.bindFilterIds({
                    fieldIds: activeFilter?.fields?.map((field) => field.id),
                });
            });
    }

    updateFilterItemsWhenChanged() {
        this.datapointsFilterService
            .onChangeSavedFilters()
            .subscribe(() => this.getFilterWorkspaceItems());
    }

    deselectFilterNodeWhenRemovedFromFilterBar() {
        this.datapointsFilterService
            .onRemovedFilterBarItem()
            .subscribe((params) => {
                this.bindFilterIds(
                    params.event,
                    params.isFilterBarItemRemoveCall
                );
            });
    }

    refreshWorkspaceItems(event) {
        this.getFilterWorkspaceItems();
    }

    truncateName(name: string): string {
        if (name.length > 20) {
            return name.substring(0, 14) + "...";
        }
        return name;
    }

    applyWorkspaceItemFilter(savedFilterConfiguration) {
        this.datapointsFilterService.emitSaveFilterApply(
            savedFilterConfiguration
        );
    }

    deleteWorkspaceItem(savedFilterConfiguration) {
        this.datapointsFilterService.emitSavedFilterDelete(
            savedFilterConfiguration
        );
    }

    removeFilterFromMap() {
        this.datapointsFilterService.emitGlobalFilterRemoved();
    }

    getDisplayName(node) {
        if (!isUndefined(node?.params?.field)) {
            const field = node.params.field;
            return field.displayName == null || field.displayName == undefined
                ? field.name
                : field.displayName;
        }
        return node.name;
    }

    distanceFilter(dataset: Dataset, distance, distanceUnit: DistanceUnit) {
        if (distance < 0) {
            this.notifService.error("The value of distance cannot be negative")
            return;
        }
        this.applyDistanceFilter(dataset, distance, distanceUnit);
    }

    resetFilter(node) {
        this.overlaysFilterDistance[node.params?.overlay?.id] = '';
        node.selected = false;
        this.enabledCheckboxes.delete(node.params?.overlay?.id)
        this.datapointsFilterService.removeFilterBarItem(
            node.params.overlay.id,
            "distance"
        );
        for (let item of node.params.overlay.fields) {
            this.datapointsFilterService.removeFilterBarItem(
                node.params.overlay.id,
                item.id
            );
        }
        for (const item of this.selectedItems) {
            if (item.params?.overlay?.id === node.params.overlay.id) {
              item.selected = false;
            }
        }
    }

    applyDistanceFilter(
        dataset: Dataset,
        distance: any,
        distanceUnit: DistanceUnit
    ) {
        this.datapointsFilterService.applyOverlayDistance(
            dataset,
            distance,
            distanceUnit
        );
        if (distance && dataset?.id) {
            this.enabledCheckboxes.add(dataset.id);
        }
    }

    isCheckboxEnabled(overlayId: string): boolean {
        return this.enabledCheckboxes.has(overlayId);
    }

    onDistanceInputChange(overlayId: string, distance: string, node): void {
        if (!distance.trim()) {
            this.resetFilter(node);
            this.enabledCheckboxes.delete(overlayId);
        }
    }

    callFilterAction($event, node) {
        const params = node.params;
        if (!$event) {
            this.selectedItems.push(node);
        } else if ($event) {
            const index = this.selectedItems.findIndex(item => item.id === node.id);
            if (index !== -1) {
              this.selectedItems.splice(index, 1);
            }
        }
        if (["dataset", "tesadataGroup", "nriUSA"].includes(params.callType)) {
            this.onFilterMenuFieldClick(!$event, params.dataset, params.field);
        } else if (params.callType == "climate") {
            this.onFilterMenuFieldClick(!$event, params.overlay, params.field);
        }
    }

    onFilterMenuFieldClick(event: boolean, dataset: any, field: any) {
        if (field.datasetId) {
            field.id = field.datasetId;
            field.name = field.datasetLabel;
        }

        if (event) {
            this.datapointsFilterService.addFilterBarItem(field, dataset);
        } else {
            this.datapointsFilterService.removeFilterBarItem(
                dataset.id,
                field.id
            );
        }
    }

    bindFilterIdRecursiveCall(parent, fieldIds, isFilterBarItemRemoveCall) {
        if (isUndefined(parent.children) && parent.length) {
            parent.forEach((element) => {
                if (!isUndefined(element.children) && element.children.length) {
                    this.bindFilterIdRecursiveCall(
                        element.children,
                        fieldIds,
                        isFilterBarItemRemoveCall
                    );
                    return;
                } else if (
                    isUndefined(element.children) &&
                    fieldIds.length > 0 &&
                    fieldIds.includes(element.id)
                ) {
                    element.selected = !isFilterBarItemRemoveCall;
                } else if (
                    isUndefined(element.children) &&
                    fieldIds.length <= 0
                ) {
                    element.selected = false;
                }
            });
        }
    }

    bindFilterIds($event, isFilterBarItemRemoveCall: boolean = false) {
        this.dataSource.data.forEach((element) => {
            if (!isUndefined(element.children) && element.children.length) {
                this.bindFilterIdRecursiveCall(
                    element.children,
                    $event.fieldIds,
                    isFilterBarItemRemoveCall
                );
                return;
            }
        });
        this.detectChanges();
    }

    detectChanges() {
        this.cd.detectChanges();
    }

    saveFilterConfiguration(isUpdateCall: boolean) {
        const activeFilter = this.datapointsFilterService.getActiveFilter();
        this.workspaceItemDialog.open(
            activeFilter,
            WorkspaceItemType.FILTER,
            isUpdateCall
        );
    }

    getFilterWorkspaceItems() {
        this.workspaceItemsService
            .getAllWorkspaceItemsForUser(
                this.dataset.id,
                WorkspaceItemType.FILTER
            )
            .subscribe((savedFilterConfigurations) => {
                this.workspaceItemsForFilter = savedFilterConfigurations.map(
                    (config) => {
                        return {
                            ...config,
                            isDeletable:
                                this.getIfFilterConfigIsDeletable(config),
                        };
                    }
                );
            });
    }

    getIfFilterConfigIsDeletable(config) {
        let isDeletable = false;

        if (
            this.userStateService.isSuperadmin ||
            this.userStateService.isAccountAdmin(this.account.id) ||
            config.access === WorkspaceItemAccess.MEMBER
        ) {
            isDeletable = true;
        }

        return isDeletable;
    }

    validateInputKeys(event:KeyboardEvent){
        if (event.key === 'e' || event.key === 'E') {
            event.preventDefault();
          }
          event.stopPropagation()
    }
}
