<div class="form-container">
    <form *ngIf="createAccountForm" [formGroup]="createAccountForm">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" name="account-name" autocomplete="off" [disabled]="isFormDisabled" [ngClass]="{'disabled-style': isFormDisabled}"/>
            <mat-error *ngIf="createAccountForm.controls.name.hasError('used') ">
                Custom Scoring with this name already exists
            </mat-error>
            <mat-error *ngIf="createAccountForm.controls.name.hasError('required') ">
                Custom Scoring name is required
            </mat-error>
            <mat-error
                *ngIf="createAccountForm.controls['name'].touched && createAccountForm.controls['name'].hasError('maxlength')">
                You exceed the maximum number of characters.
            </mat-error>
            <mat-error
                *ngIf="createAccountForm.controls['name'].touched && createAccountForm.controls['name'].hasError('pattern')">
                You cannot use special characters. Space is allowed only between words.
            </mat-error>
        </mat-form-field>

        <!-- --------------- -->

        <mat-form-field>
            <mat-select [ngClass]="{'disabled-style': isFormDisabled}" [disabled]="isFormDisabled" formControlName="fieldTextAndNumeric" placeholder="Select Field" (selectionChange)="onFieldTypeChange($event)" style="font-size: 12px; color: black !important;">
              <mat-option *ngFor="let data of externalOverlays" [value]="data.id">
                  {{ data.displayName ? data.displayName : data.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createAccountForm.controls.fieldTextAndNumeric.hasError('required')">
              Field is required.
            </mat-error>
          </mat-form-field>


            <!--  -->


<!-- Dynamic Field Ranges -->
     <!-- Dynamic Field Ranges cdkDropList (cdkDropListDropped)="drop($event)" cdkDrag -->
     <div [class.drag-disabled]="isFormDisabled" formArrayName="ranges" class="range-list" *ngIf="isDropdownValueSelected">
        <div *ngFor="let range of ranges.controls; let i = index" [formGroupName]="i" class="range-item" style="display: flex; align-items: center; gap: 10px; flex-wrap: nowrap;">
            <ng-container *ngIf="isNumberField">
                <mat-form-field style="flex: 1; min-width: 100px;" >
                    <input [ngClass]="{'disabled-style': isFormDisabled}" [disabled]="isFormDisabled" matTooltipPosition="above" [matTooltip]="minMaxValues?.minValue" matInput placeholder="Min Value" formControlName="minValue" (input)="formatNumber($event)" />
                </mat-form-field>
                <span style="margin: 0 10px;">to</span>
                <mat-form-field style="flex: 1; min-width: 100px;" >
                    <input [ngClass]="{'disabled-style': isFormDisabled}" [disabled]="isFormDisabled" matTooltipPosition="above" [matTooltip]="minMaxValues?.maxValue" matInput placeholder="Max Value" formControlName="maxValue" (input)="formatNumber($event)"/>
                </mat-form-field>
                <span style="margin: 0 10px;">=</span>
            </ng-container>

            <ng-container *ngIf="isTextField && textOptions?.length === 0">
                <div style="display: inline-flex; align-items: center;">
                    There are no options for this field.
                </div>
            </ng-container>

            <ng-container *ngIf="isTextField  && textOptions?.length > 0">
                <div style="display: inline-flex; align-items: center;">
                    <mat-form-field style="margin-right: 15px;">

                        <mat-select [ngClass]="{'disabled-style': isFormDisabled}" [disabled]="isFormDisabled" color="primary" style="font-size: 12px;" formControlName="textValues" multiple placeholder="Select Values">
                            <mat-option *ngFor="let option of textOptions" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span style="margin-left: 15px; margin-right: 15px;">=</span>
                </div>
            </ng-container>

            <mat-form-field *ngIf="isNumberField || (isTextField && textOptions?.length > 0)" style="flex: 1; min-width: 130px;">
                <div style="margin-right: -6px;">
                    <input [ngClass]="{'disabled-style': isFormDisabled}" [disabled]="isFormDisabled" matInput style="margin-right: -30px;" placeholder="Add Label" formControlName="label" />
                </div>
            </mat-form-field>
            <!-- <div *ngIf="isNumberField || (isTextField && textOptions?.length > 0)" style="display: flex; align-items: center; margin-right: 7px;">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.97548H14.3333M1 10.3088H14.3333M5.16667 13.6422L7.66667 16.1422L10.1667 13.6422M5.16667 3.64215L7.66667 1.14215L10.1667 3.64215" stroke="#545454" stroke-opacity="0.54" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div> -->

            <!-- REMOVE RANGE -->
            <div *ngIf="isNumberField || (isTextField && textOptions?.length > 0)" style="margin-left: -12px;">
            <button [ngClass]="{'disabled-style': isFormDisabled}" [disabled]="isFormDisabled" mat-icon-button color="black" (click)="removeRange(i)">
                <mat-icon>close</mat-icon>
            </button>
            </div>
        </div>
    </div>

        <!-- Add Range Button -->
        <button
        [ngClass]="{'disabled-style': isFormDisabled}"
        [disabled]="isFormDisabled"
        (click)="addRange()"
        *ngIf="isDropdownValueSelected && (isNumberField || (isTextField && textOptions?.length > 0))"
        mat-flat-button
        style="display: flex; align-items: center; padding: 8px 16px; font-size: 12px; min-width: 150px; max-width: 200px; margin-right: 20px; border-radius: 6px; background-color: white; border: 1px solid white;">
    <!-- Plus rounded icon -->
    <svg style="display: flex; width: 24px; height: 24px; margin-left: -5px;"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
            <path d="M24 0v24H0V0z" />
            <path d="M15 3.523a8.865 8.865 0 0 0-6 0M3.157 10.358a8.981 8.981 0 0 1 3-5.196M6.157 18.838a8.982 8.982 0 0 1-3-5.196M9 20.477c1.94.697 4.06.697 6 0M20.843 10.358a8.981 8.981 0 0 0-3-5.196M17.843 18.838a8.982 8.982 0 0 0 3-5.196M10 12h4M12 14v-4"
                  stroke="#007BFF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
        </g>
    </svg>
    <span style="white-space: nowrap; margin-left: 10px; color: black;">Add New Range</span>
    </button>



        <!-- Validation Error Message -->
        <mat-error *ngIf="hasValidationError">
        <p>Min Value should not be greater than Max Value.</p>
        </mat-error>
        <mat-error *ngIf="createAccountForm.controls['ranges'].hasError('required')">
            At least one range is required.
        </mat-error>

        <mat-error *ngIf="createAccountForm.controls['ranges'].invalid && (createAccountForm.controls['ranges'].dirty || createAccountForm.controls['ranges'].touched)">
            <p>At least one range must be valid.</p>
        </mat-error>

        <div *ngIf="isFormDisabled">
            <br>
            <mat-error style="padding: 10px;">
                To update the settings, please unapply the custom scoring. Once unapplied, the fields will be enabled for editing.
            </mat-error>
        </div>
        <br>
        <br>
        <div *ngIf="isDropdownValueSelected" style="display: flex; gap: 10px; align-items: center;justify-content: space-evenly;">
            <button [ngClass]="{'disabled-style': isFormDisabled}" mat-flat-button color="accent" style="border-radius: 5px;font-size: 43px;margin-left:-10px;padding:0px 70px" (click)="submitAccount()" [disabled]="isFormDisabled">Save
            </button>
            <button style="border-radius: 5px;font-size: 43px;padding: 0px 70px;" mat-flat-button [color]="isApplied ? 'primary' : 'accent'" (click)="toggleApplyUnapply()" [disabled]="!isEdit" [ngClass]="{'disabled-style': !isEdit}">{{ isApplied ? 'Unapply' : 'Apply' }}
            </button>
        </div>

    </form>

</div>

