import { Injectable } from "@angular/core";
import { Observable, Subject } from 'rxjs';
import { ClusterDatapoint } from '../../dataset/clustering/cluster-datapoint';
import { ClusteringRequest } from '../../dataset/clustering/clustering-request';

@Injectable({ providedIn: 'root' })
export class DataPointsServiceState {
    datapointUpdate$: Observable<boolean>;
    onAnalyticsInit$: Observable<boolean>;
    onDataPointSelected$: Observable<ClusterDatapoint>;
    onApplySettings$: Observable<ClusteringRequest>;
    onCancelClustering$: Observable<boolean>;
    onCreateMemberSuccess$: Observable<boolean>;
    onAddressLocationProfileCosed: Observable<boolean>;
    onUpdateDataPointInit$: Observable<boolean>;
    onCreateDraftSuccess$: Observable<boolean>;
    onUpdateDraftSuccess$: Observable<boolean>;
    onAddressLocationProfileInit$: Observable<boolean>;
    onAddressLocationProfileDestroyed$: Observable<boolean>;
    onCreateOverlayGroupSuccess$: Observable<boolean>;
    onEditOverlayGroupSuccess$: Observable<boolean>;
    onDeleteOverlayGroupSuccess$: Observable<boolean>;
    onApplyLegendsSettings$: Observable<string>;
    tensorflightAddressProfilePolygon$: Observable<string>;
    externalCallCount: number = 0;
    activeSearchLocation: any = null;
    munichreFieldsWithValues: any[] = [];
    dataSource: any;
    riskDataSource: any;
    tensorflightFieldsWithValues: any[] = [];
    e2valueFieldsWithValues: any[] = [];

    private readonly _datapointUpdate: Subject<boolean>;
    private readonly _onAnalyticsInit: Subject<boolean>;
    private readonly _onDataPointSelected: Subject<ClusterDatapoint>;
    private readonly _onApplySettings: Subject<ClusteringRequest>;
    private readonly _onCancelClustering: Subject<boolean>;
    private readonly _onAddressLocationProfileClosed: Subject<boolean>;
    private readonly _onUpdateDataPointInit: Subject<boolean>;
    private readonly _onCreateDraftSuccess: Subject<boolean>;
    private readonly _onUpdateDraftSuccess: Subject<boolean>;
    private readonly _onAddressLocationProfileInit: Subject<boolean>;
    private readonly _onAddressLocationProfileDestroyed: Subject<boolean>;
    private readonly _onCreateOverlayGroupSuccess: Subject<boolean>;
    private readonly _onEditOverlayGroupSuccess: Subject<boolean>;
    private readonly _onDeleteOverlayGroupSuccess: Subject<boolean>;
    private readonly dropdownValueSubject: Subject<string>;
    private readonly _tensorflightAddressProfilePolygonSubject: Subject<string>;
    private readonly externalOverlaysValueChange: Subject<string> = new Subject<string>();

    constructor() {
        this._datapointUpdate = new Subject<boolean>();
        this.datapointUpdate$ = this._datapointUpdate.asObservable();

        this._onDataPointSelected = new Subject<ClusterDatapoint>();
        this.onDataPointSelected$ = this._onDataPointSelected.asObservable();

        this._onApplySettings = new Subject<ClusteringRequest>();
        this.onApplySettings$ = this._onApplySettings.asObservable();

        this._onCancelClustering = new Subject<boolean>();
        this.onCancelClustering$ = this._onCancelClustering.asObservable();

        this._onAddressLocationProfileClosed = new Subject<boolean>();
        this.onAddressLocationProfileCosed = this._onAddressLocationProfileClosed.asObservable();

        this._onUpdateDataPointInit = new Subject<boolean>();
        this.onUpdateDataPointInit$ = this._onUpdateDataPointInit.asObservable();

        this._onCreateDraftSuccess = new Subject<boolean>();
        this.onCreateDraftSuccess$ = this._onCreateDraftSuccess.asObservable();

        this._onUpdateDraftSuccess = new Subject<boolean>();
        this.onUpdateDraftSuccess$ = this._onUpdateDraftSuccess.asObservable();

        this._onAddressLocationProfileInit = new Subject<boolean>();
        this.onAddressLocationProfileInit$ = this._onAddressLocationProfileInit;

        this._onAddressLocationProfileDestroyed = new Subject<boolean>();
        this.onAddressLocationProfileDestroyed$ = this._onAddressLocationProfileDestroyed;

        this._onCreateOverlayGroupSuccess = new Subject<boolean>();
        this.onCreateOverlayGroupSuccess$ = this._onCreateOverlayGroupSuccess;

        this._onEditOverlayGroupSuccess = new Subject<boolean>();
        this.onEditOverlayGroupSuccess$ = this._onEditOverlayGroupSuccess;

        this._onDeleteOverlayGroupSuccess = new Subject<boolean>();
        this.onDeleteOverlayGroupSuccess$ = this._onDeleteOverlayGroupSuccess;

        this._onAnalyticsInit = new Subject<boolean>();
        this.onAnalyticsInit$ = this._onAnalyticsInit;

        this.dropdownValueSubject = new Subject<string>();

        this._tensorflightAddressProfilePolygonSubject = new Subject<string>();
        this.tensorflightAddressProfilePolygon$ = this._tensorflightAddressProfilePolygonSubject;
    }

    emitDropDownValue(value: string) {
        this.dropdownValueSubject.next(value);
    }

    getActiveDropDownValueSubject(): Observable<string> {
        return this.dropdownValueSubject.asObservable();
    }

    emitOnLocationProfileInit(): void {
        this._onAddressLocationProfileInit.next();
    }

    emitOnLocationProfileDestroyed(): void {
        this._onAddressLocationProfileDestroyed.next();
    }

    emitDatapointUpdate(value: boolean): void {
        this._datapointUpdate.next(value);
    }

    emitOnDataPointSelecetd(cluster: ClusterDatapoint): void {
        this._onDataPointSelected.next(cluster);
    }

    emitOnApplySettings(settings: ClusteringRequest): void {
        this._onApplySettings.next(settings);
    }

    emitOnCancelClustering(): void {
        this._onCancelClustering.next(true);
    }

    emitOnAddressLocationProfileClose(): void {
        this._onAddressLocationProfileClosed.next(true);
    }

    emitOnUpdateDatapointInit(): void {
        this._onUpdateDataPointInit.next(true);
    }

    emitOnCreateDraftSuccess(): void {
        this._onCreateDraftSuccess.next(true);
    }

    emitOnUpdateDraftSuccess(): void {
        this._onUpdateDraftSuccess.next(true);
    }

    emitOnCreateOverlayGroupSuccess(): void {
        this._onCreateOverlayGroupSuccess.next(true);
    }

    emitOnEditOverlayGroupSuccess(): void {
        this._onEditOverlayGroupSuccess.next(true);
    }

    emitOnDeleteOverlayGroupSuccess(): void {
        this._onDeleteOverlayGroupSuccess.next(true);
    }

    emitOnAnalyticsInit(): void {
        this._onAnalyticsInit.next(true);
    }

    emitOnTensorflightAddressProfilePolygonFetch(polygon): void {
        this._tensorflightAddressProfilePolygonSubject.next(polygon)
    }

    getTensorflightAddressProfilePolygon(): Observable<string> {
        return this._tensorflightAddressProfilePolygonSubject.asObservable();
    }

    emitOnExternalOverlaySelected(selectedValue): void {
        this.externalOverlaysValueChange.next(selectedValue)
    }

    getExternalOverlaySelection(): Observable<string> {
        return this.externalOverlaysValueChange.asObservable();
    }

    unsubscribeTensorflightAddressProfilePolygonFetch(): void {
        this._tensorflightAddressProfilePolygonSubject.next();
    }
}
