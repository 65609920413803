import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {CustomScoring, CustomScoringScope, LayerExposureSummary} from '../model/layer-service';
import { SortController } from "ag-grid-community";
import {DatapointFilter} from '../../../model/datapoint/filter/datapoint-filter';

@Injectable({
    providedIn: "root",
})
export class LayerExposedService {
    constructor(private readonly http: HttpClient) {}
    private baseDomain = "/custom-scoring";

    getCustomScoringsForAccount(accountID : number, scope : CustomScoringScope, datasetID? :string){
        let params = new HttpParams().set('scope',scope);
        if(datasetID) {
            params.append('datasetID',datasetID);
        }
         let url = this.baseDomain+`/${accountID}?scope=${scope}`;
         if (datasetID) {
            url += `&datasetID=${datasetID}`;
         }
        return this.http.get<CustomScoring[]>(
            url
        );
    }

    getLocationCustomScoringFields(datasetId){
        return this.http.get<CustomScoring[]>(
            this.baseDomain+`/fields/${datasetId}`
        );
    }

    createCustomScoring(accountID: any, payload) {
        return this.http.post<void>(`/custom-scoring/${accountID}/save`, payload);
    }

    deleteCustomScoring(accountID: any, customScoringId) {
        return this.http.delete<boolean>(`/custom-scoring/${accountID}/delete/${customScoringId}`);
    }

    updateAppliedFlag(accountID, customScoringID, payload) {
        return this.http.put<boolean>(`/custom-scoring/${accountID}/update/${customScoringID}`, payload);
    }

    getCustomScoring(accountID, customScoringID) {
        return this.http.get<CustomScoring>(`/custom-scoring/${accountID}/scoring/${customScoringID}`);
    }

    updateCustomScoring(accountID: any, payload) {
        return this.http.put<void>(`/custom-scoring/${accountID}/update`, payload);
    }

    applyFlag(accountID, customScoringID, applied_flag) {
        return this.http.post<boolean>(`/custom-scoring/${accountID}/apply/${customScoringID}/${applied_flag}`,{});
    }

    createLayerExpose(accountID: any, payload) {
        return this.http.post<void>(`/layer-expose/${accountID}/save`, payload);
    }

    getLayerExpose(accountID : number) {
        return this.http.get<any>(`/layer-expose/${accountID}/equations`);
    }

    deleteLayerExpose(accountID: any, equationId) {
        return this.http.delete<boolean>(`/layer-expose/${accountID}/delete/${equationId}`);
    }

    toggleFlagIsApplied(accountID, equationId) {
        return this.http.put<boolean>(`/layer-expose/${accountID}/toggle/${equationId}`,{});
    }

    getLayerExposeView(accountID, equationId) {
        return this.http.get<any>(`/layer-expose/${accountID}/get/${equationId}`);
    }

    updateLayerExpose(accountID: any, equationId, payload) {
        return this.http.put<void>(`/layer-expose/${accountID}/update/${equationId}`, payload);
    }

    getAppliedLayerExpose(accountID: number) {
        return this.http.get<any>(`/layer-expose/${accountID}/applied-equations`);
    }

    getLayerSummaryResponse(request: DatapointFilter, accountID: number, equationId: number) {
        return this.http.post<LayerExposureSummary>(`/layer-expose/${accountID}/equation/${equationId}`, request);
    }
}
