<div class="page-root">
    <map-account-header-component *ngIf="!userStateService.principal.provider">
        <ng-container *ngIf="userStateService.isSuperadmin else overlayTabs">
            <settings-header-breadcrumbs [breadCrumbs]="breadCrumbs"
                [componentName]="componentName"></settings-header-breadcrumbs>
        </ng-container>

        <ng-template #overlayTabs>
            <map-overlay-tabs [breadCrumbs]="breadCrumbs" [componentName]="componentName"></map-overlay-tabs>
        </ng-template>
    </map-account-header-component>
    <div class="page">
        <div class="page-container">
            <div class="table-actions-with-filter">
                <div class="toolbar-title" style="margin-bottom: 8px;">
                    API calls
                </div>
                <form [formGroup]="filterForm" (submit)="filterAPIMeteringData()" class="api-metering-filter-form">
                    <div class="api-metering-form">
                        <div class="api-metering-form-fields">
                            <div>
                                <mat-form-field class="chip-list">
                                    <mat-chip-list #serviceNameList>
                                        <input placeholder="Filter by service names" #serviceNameInput
                                            [formControl]="serviceNameControl" [matAutocomplete]="auto"
                                            [matChipInputFor]="serviceNameList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            (matChipInputTokenEnd)="add($event, filterTypes.SERVICE)">
                                    </mat-chip-list>
                                    <mat-autocomplete #auto="matAutocomplete"
                                        (optionSelected)="selected($event, filterTypes.SERVICE)">
                                        <mat-option *ngFor="let service of filteredServiceNames | async"
                                            [value]="service.name" [disabled]="serviceNames.includes(service.name)">
                                            {{service.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </div>

                            <div>
                                <mat-form-field class="chip-list" *ngIf="!userStateService.principal.provider">
                                    <mat-chip-list #usernameChipList>
                                        <input placeholder="Filter by usernames" #usernameInput
                                            [formControl]="usernameControl" [matAutocomplete]="usernameAuto"
                                            [matChipInputFor]="usernameChipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            (matChipInputTokenEnd)="add($event, filterTypes.USERNAME)">
                                    </mat-chip-list>
                                    <mat-autocomplete #usernameAuto="matAutocomplete"
                                        (optionSelected)="selected($event, filterTypes.USERNAME)">
                                        <mat-option *ngFor="let user of filteredUsernames | async" [value]="user.name"
                                            [disabled]="usernames.includes(user.name)">
                                            {{user.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </div>

                            <mat-form-field class="date-input">
                                <input matInput [matDatepicker]="picker1" placeholder="Start date"
                                    formControlName="startDate">

                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <mat-icon matDatepickerToggleIcon (click)="clearDate('startDate')">clear</mat-icon>
                            </mat-form-field>

                            <mat-form-field class="date-input">
                                <input matInput [matDatepicker]="picker2" placeholder="End date"
                                    formControlName="endDate">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                                <mat-icon matDatepickerToggleIcon (click)="clearDate('endDate')">clear</mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="api-metering-form-action-buttons">
                            <button type="submit" mat-flat-button color="accent">
                                <!-- filter-icon -->
                                <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                    style="margin-right: 4px;">
                                    <g fill="none" fill-rule="evenodd">
                                        <path d="M0 0h24v24H0z" />
                                        <path
                                            d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2zM9 7v2M9 12v5M15 7v5M15 15v2"
                                            stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M10 12H8a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1zM16 15h-2a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1z"
                                            stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </g>
                                </svg>
                                <span>Filter</span>
                            </button>
                            <button *ngIf="isFilterActive()" type="button" mat-flat-button color="accent"
                                (click)="clearFilter()">
                                <!-- cross-icon -->
                                <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg"
                                    style="margin-right: 4px;">
                                    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"
                                        stroke="#ffffff" stroke-width="2">
                                        <path d="m1 1 3.333 3.333L1 7.667" />
                                        <path d="M7.333 1 4 4.333l3.333 3.334" />
                                    </g>
                                </svg>
                                <span>Clear</span>
                            </button>
                            <button type="button" mat-flat-button color="accent" (click)="downloadAPIMeteringData()">
                                <!-- download-icon -->
                                <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                    style="margin-right: 4px;">
                                    <g fill="none" fill-rule="evenodd">
                                        <path stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M15.557 11.11 12 14.669l-3.557-3.557M12 3.997v10.671M20.003 16.446h0a3.557 3.557 0 0 1-3.557 3.557H7.554a3.557 3.557 0 0 1-3.557-3.557h0" />
                                        <path d="M-.005-.005h24.01v24.01H-.005z" />
                                    </g>
                                </svg>
                                <span>Download</span>
                            </button>
                        </div>
                    </div>
                    <div class="external-chip-list">
                        <div>
                            <mat-chip class="filter-chip" *ngFor="let service of serviceNames"
                                (removed)="remove(service, filterTypes.SERVICE)">
                                {{service}}
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                        </div>

                        <div>
                            <mat-chip class="filter-chip" *ngFor="let username of usernames"
                                (removed)="remove(username, filterTypes.USERNAME)">
                                {{username}}
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                        </div>
                    </div>
                </form>
            </div>


            <div class="table-container">
                <div class="table-content">
                    <table *ngIf="apiMeteringDataSource && !isTableDataLoading" [dataSource]="apiMeteringDataSource"
                        matSort (matSortChange)="overlaySortChange($event)" [matSortActive]="filterObject.sortBy"
                        [matSortDirection]="'asc'" mat-table>
                        <ng-container matColumnDef="service">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>Service</th>
                            <td *matCellDef="let element" mat-cell>{{
                                element.service }}</td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold;"></td>
                        </ng-container>
                        <ng-container matColumnDef="accountName">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>Client</th>
                            <td *matCellDef="let element" mat-cell>{{
                                element.accountName }}</td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold;"></td>
                        </ng-container>
                        <ng-container matColumnDef="username">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>Username</th>
                            <td *matCellDef="let element" mat-cell>{{
                                element.username }}</td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold;"></td>
                        </ng-container>
                        <ng-container matColumnDef="createdDate">
                            <th *matHeaderCellDef mat-header-cell>Date</th>
                            <td *matCellDef="let element" mat-cell>
                                {{ element.createdDate }}
                            </td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold;">Total </td>
                        </ng-container>
                        <ng-container matColumnDef="counter">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>Number of API Calls</th>
                            <td class="counter-element" *matCellDef="let element" mat-cell>{{ element.counter }}</td>
                            <td style="padding-left: 33px !important;" class="total-result" mat-footer-cell *matFooterCellDef> {{ totalResultCount }}</td>
                        </ng-container>
                        <tr *matHeaderRowDef="( userStateService.principal.isSuperadmin ? currentDisplayedColumns :
                         userStateService.principal.provider ? providerDisplayedColumns : accountAdminDisplayedColumns );
                         sticky: true" mat-header-row></tr>
                        <tr *matRowDef="let row; columns: userStateService.principal.isSuperadmin ? currentDisplayedColumns :
                                userStateService.principal.provider ? providerDisplayedColumns : accountAdminDisplayedColumns "
                            mat-row class="cursor-pointer">
                        </tr>

                        <tr mat-footer-row
                            *matFooterRowDef="( userStateService.principal.isSuperadmin ? currentDisplayedColumns :
                        userStateService.principal.provider ? providerDisplayedColumns : accountAdminDisplayedColumns )"></tr>

                    </table>
                    <div *ngIf="isTableDataLoading" class="spinnder-container">
                        <mat-spinner diameter="40" color="accent"></mat-spinner>
                    </div>
                </div>
                <map-paginator [numberOfItemsPerPage]="filterObject.limit"
                    (paginationChangeEvent)="onPaginationChange($event)"
                    [totalNumberOfElements]="paginationInfo?.count">
                </map-paginator>
            </div>
        </div>
    </div>
</div>
