import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  private dataSource = new BehaviorSubject<any>(null);
  public currentData = this.dataSource.asObservable();

  updateData(data: any) {
    this.dataSource.next(data);
  }

}
