import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserStateService } from '../../auth/user-state-service';
import { MatTabsModule } from '@angular/material/tabs';
import { OverlaysService } from '../../data-access-layer/global-overlays/overlays.service';
import { ActivatedRoute } from '@angular/router';
import { LayerExposedService } from './services/layer-exposed.service';
import { MatTableDataSource } from '@angular/material/table';
import { CustomScoring, CustomScoringScope, LayerExpose } from './model/layer-service';
import { Dataset } from 'src/app/model/dataset/dataset';
import { TessadataDatasetStructure } from 'src/app/core/tessadata/tessadata-dataset-structure';
import { SidePanelComponent } from 'src/app/core/side-panel/side-panel.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogModel } from 'src/app/model/dialog/dialog-model';
import { take } from "rxjs/operators";
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { NotifService } from 'src/app/core/notification/notif.service';
import { GroupService } from 'src/app/data-access-layer/groups/group.service';
import { DatapointsAggregateService } from 'src/app/data-access-layer/datapoints/datapoints-aggregate.service';
import { AddLayerExposedComponent } from './add-layer-exposed/add-layer-exposed.component';
import { CacheService } from './services/cache.service';
import { AccountService } from 'src/app/data-access-layer/account/account.service';

export enum DatasetType {
  LOCATIONS = 'Locations',
  EXTERNAL = 'External',
  GLOBAL = 'Global'
}

@Component({
  selector: 'layer-exposed',
  templateUrl: './layer-exposed.component.html',
  styleUrls: ['./layer-exposed.component.scss']
})
export class LayerExposedComponent implements OnInit {

  breadCrumbs = ["Home", "Accounts", "Settings"];
  accountId: number;
  cacheDurationTime = 2;
  layerExposeList: any;
  dataSource: any;
  globalOverlays: Dataset[];
  externalOverlays: Dataset[];
  displayedColumns: string[] = ['name', 'baseValue', 'layerValue', 'attachmentPoint', 'Actions'];
  applyIcon: string = "icon1"
  isOverlays:boolean = false
  actionColumnNameTitle: string = 'Actions';
  dataToEdit: any;
  @ViewChild("layerExposedPanel") layerExposedPanel: SidePanelComponent;
  @ViewChild("addLayerExposed") addLayerExposed: AddLayerExposedComponent;
  isActionGloablAndExtenalDataset: boolean = true;
  dataSetRecord: any;
  isEdit: boolean = false;
  panelTitle: string = '';
  locationDatasetDropdown: any; // Base Value
  policyDatasetDropdown: any; // Limit Value and Attachment Point
  locationDataset: any;

  constructor(
    public userStateService: UserStateService,
    private cacheService: CacheService,
    private layerExposeService: LayerExposedService,
    private readonly route: ActivatedRoute,
    public readonly dialog: MatDialog,
    private readonly notifService: NotifService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly accountService: AccountService,

  ) {
    this.userStateService = userStateService;
    this.cacheService = cacheService;
  }

  ngOnInit() {
    this.accountId = +this.route.snapshot.paramMap.get("accountId");

    this.getLayerExposeList();
    }

    public getLayerExposeList() {
        this.dataSource = null;
        this.isOverlays = false;

        this.layerExposeService.getLayerExpose(this.accountId).subscribe((data) => {
            this.layerExposeList = data
                .map((item) => ({
                    ...item,
                    name: item.name.trimStart(),
                }))
                .sort((a, b) => a.name.localeCompare(b.name));

            this.accountService.findAccount(this.accountId).subscribe((account) => {

                const locationDataset = account.datasets.find(
                    (dataset: any) => dataset.name === 'Locations'
                );
                if (locationDataset) {
                    this.locationDataset = locationDataset;
                    this.locationDatasetDropdown = locationDataset.fields.filter(
                        (field) => field.type === 'NUMBER_FIELD'
                    );
                }

                const policyDataset = account.datasets.find(
                    (dataset: any) => dataset.name === 'Policies'
                );
                if (policyDataset) {
                    this.policyDatasetDropdown = policyDataset.fields.filter(
                        (field) => field.type === 'NUMBER_FIELD'
                    );
                }

                this.layerExposeList = this.layerExposeList.map((item) => {
                    const baseField = (this.locationDatasetDropdown || []).find(
                        (field) =>

                            field.id === item.baseValue
                    );

                    const attachField = (this.policyDatasetDropdown || []).find(

                        (field) => field.id === item.attachmentPoint
                    );

                    const layerField = (this.policyDatasetDropdown || []).find(
                        (field) => field.id === item.layerValue
                    );

                    return {
                        ...item,
                        baseValue: baseField ? baseField.name : item.baseValue,
                        attachmentPoint: attachField ? attachField.name : item.attachmentPoint,
                        layerValue: layerField ? layerField.name : item.layer,
                    };
                });

                this.dataSource = new MatTableDataSource<any>(this.layerExposeList);
            });
        });
    }


    editLayerExpose(element): void {
    this.layerExposeService.getLayerExposeView(this.accountId,element.id).subscribe((data) => {
        this.isEdit = true;
        this.dataToEdit = data;
        this.changeDetector.detectChanges();
        this.layerExposedPanel.showPanel();
    });
   }


  toggleIcon(element) {
    this.applyIcon = this.applyIcon === "icon1" ? "icon2" : "icon1";
    this.layerExposeService.toggleFlagIsApplied(this.accountId, element.id).subscribe((data) => {
      this.getLayerExposeList();
      this.notifService.success(
          "The layer exposed applied flag has been updated.."
      );
    });

  }

  get componentName() {
    return this.constructor.name;
  }

  ngOnDestroy() {
    this.layerExposedPanel.hidePanel();
    this.layerExposedPanel.closePanel();
    this.addLayerExposed.createAccountForm.reset();
    this.addLayerExposed.initForm();
  }

  addNewLayerExpose() {
    this.addLayerExposed.clearFormValidators(this.addLayerExposed.createAccountForm);
    this.addLayerExposed.createAccountForm.reset();
    this.addLayerExposed.initForm();

    this.changeDetector.detectChanges();
    this.isEdit = false;
    this.dataToEdit = null;
    this.layerExposedPanel.showPanel();
    this.changeDetector.detectChanges();
  }

  onCancelClose2() {
    this.layerExposedPanel.hidePanel();
    this.layerExposedPanel.closePanel();
    this.changeDetector.detectChanges();
  }

  onCreateSuccess() {
    this.layerExposedPanel.hidePanel();
    this.layerExposedPanel.closePanel();
    this.changeDetector.detectChanges();
    this.getLayerExposeList();

  }

  deleteLayerExpose(layerExpose): void {
    const dialogRef = this.dialog.open(DialogComponent, {
        data: new DialogModel(
            "Confirm Action",
            `Are you sure you want to delete the layer expose?`
        ),
    });
    dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((dialogResult) => {
            if (dialogResult) {
              localStorage.removeItem("dynamicIndicatorEquationId_"+this.accountId);
              this.layerExposeService
                    .deleteLayerExpose(this.accountId, layerExpose.id)
                    .subscribe((success) => {
                        this.getLayerExposeList();
                        this.notifService.success(
                            "The layer expose has been deleted."
                        );
                    });
            }
        });
  }
}