import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    Input,
} from "@angular/core";
import {
    FormArray,
    FormBuilder,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { AccountService } from "../../../data-access-layer/account/account.service";
import { CreateAccountRequest } from "../../../model/account/account";
import { NotifService } from "../../../core/notification/notif.service";
import { UserStateService } from "../../../auth/user-state-service";
import { AuthService } from "../../../auth/auth.service";
import { OverlaysService } from "src/app/data-access-layer/global-overlays/overlays.service";
import { ActivatedRoute } from "@angular/router";
import { CustomScoringService } from "../services/custom-scoring.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { GroupService } from "src/app/data-access-layer/groups/group.service";
import { DatapointsAggregateService } from "src/app/data-access-layer/datapoints/datapoints-aggregate.service";
import { CustomScoringScope } from "../model/custom-service";
import { catchError } from 'rxjs/operators';
import { of, range } from 'rxjs';
import { CustomErrorStateMatcher } from "src/app/shared/error-state-matcher";

@Component({
    selector: "add-custom-scoring",
    templateUrl: "./add-custom-scoring.component.html",
    styleUrls: ["./add-custom-scoring.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCustomScoringComponent implements OnInit {
    @Output() updatedSuccessfully = new EventEmitter();
    @Output() onCancelClose = new EventEmitter();
    @Input() existingData: any; 
    @Input() fromGlobal: any;
    @Input() globalDataSetRecord: any;
    @Input() externalOverlaysFromGlobal: any;
    @Input() locationDatasetRecord: any;
    @Input() dataToEdit: any;
    @Input() isEdit: boolean;
    @Input() dropDownValueTextOptions: any;
    @Input() customScoringScope: any;
    createAccountForm: UntypedFormGroup;
    datasetLinkMap = {};
    externalOverlays: any;
    accountId: number;
    selectedType: string = '';
    isNumberField = false;
    isTextField = false;
    isDropdownValueSelected = false;
    textOptions = [];
    minMaxValues : any;
    locationDataset: any;
    isFormDisabled = false;
    minValues: any;
    isApplied: boolean = false;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly accountService: AccountService,
        private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly userStateService: UserStateService,
        private readonly auth: AuthService,
        private overlayService: OverlaysService,
        private readonly route: ActivatedRoute,
        private customService: CustomScoringService,
        private fb: FormBuilder,
        private readonly groupService: GroupService,
        private readonly datapointsAggregateService: DatapointsAggregateService,

    ) {
    }
    ngOnChanges() {
        if (!this.isEdit) {
            this.initForm();
            this.createAccountForm.controls.name.setValue('');
            this.createAccountForm.controls.fieldTextAndNumeric.setValue('');
            this.createAccountForm.controls.ranges.setValue([]);
        }
        if (this.externalOverlaysFromGlobal) {
            this.externalOverlays = this.externalOverlaysFromGlobal;
            this.locationDataset = this.locationDatasetRecord;
        }
        if (this.existingData) {
            this.patchExistingData();
        }
        if (this.globalDataSetRecord) {
            this.accountId = +this.route.snapshot.paramMap.get("accountId");
            this.initForm();
            if (this.existingData) {
                this.patchExistingData();
            }
            if ((this.customScoringScope === CustomScoringScope.EXTERNAL || this.customScoringScope === CustomScoringScope.GLOBAL) && !this.isEdit) {
                this.isDropdownValueSelected = false;
            }
            this.getData();
        } 
    }

    ngOnInit() {
        if (!this.isEdit) {
            this.initForm();
            this.createAccountForm.reset();
            this.createAccountForm.controls.name.setValue('');
            this.createAccountForm.controls.fieldTextAndNumeric.setValue('');
            this.createAccountForm.controls.ranges.setValue([]);
        }
        if (this.globalDataSetRecord) {
            this.accountId = +this.route.snapshot.paramMap.get("accountId");
            this.initForm();
            if (this.existingData) {
                this.patchExistingData();
            }
            this.getData();
        } else {
            this.accountId = +this.route.snapshot.paramMap.get("accountId");
            if (this.existingData) {
                this.patchExistingData();
            }
            this.getData();
        }

    }

    formatNumber(event: any): void {
        const input = event.target as HTMLInputElement;
        const value = input.value.replace(/,/g, ''); // Remove existing commas
        if (!isNaN(Number(value))) {
            input.value = this.formatWithCommas(value);
            this.createAccountForm.get('minValue')?.setValue(value);
        } else {
            input.value = this.formatWithCommas(this.createAccountForm.get('minValue')?.value || '');
        } 
    }

    formatWithCommas(value: string, isComingFromUpdate = false): string {
        if (isComingFromUpdate) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    patchExistingData(): void {
        this.createAccountForm.patchValue({
            name: this.existingData.name,
            fieldTextAndNumeric: this.existingData.fieldName,
        });

        this.onFieldTypeChange({
            value: this.existingData.fieldName,
        }, true);
        if (this.existingData.scoring && this.existingData.scoring.length > 0) {
            this.isDropdownValueSelected = true;


            const rangesFormArray = this.createAccountForm.get('ranges') as FormArray;

            while (rangesFormArray.length !== 0) {
                rangesFormArray.removeAt(0);
            }

            this.existingData.scoring.forEach((range) => {
            const rangeGroup = this.fb.group({
                minValue: [this.formatWithCommas(range.minNumberValue, true)],
                maxValue: [this.formatWithCommas(range.maxNumberValue, true)],
                textValues: [range.textValues],
                label: [range.label, Validators.required],
            });

            rangesFormArray.push(rangeGroup);
            });

            this.changeDetector.detectChanges();
        }

        this.isApplied = this.existingData?.applied || false;
        this.isFormDisabled = this.isApplied;
        this.isApplied ? this.createAccountForm.disable() : this.createAccountForm.enable();
    }

    toggleApplyUnapply(): void {
        this.isApplied = !this.isApplied;
        this.isFormDisabled = this.isApplied;
    
        if (this.isApplied) {
            this.createAccountForm.disable();
            this.customService.applyFlag(this.accountId, this.existingData._id, true).subscribe(() => {
                this.notifService.success("Successfully applied.");
                this.refreshListing();
            });
        } else {
            this.createAccountForm.enable();
            this.customService.applyFlag(this.accountId,this.existingData._id, false).subscribe(() => {
                this.notifService.success("Successfully unapplied.");
                this.refreshListing();
            });
        }
        this.changeDetector.detectChanges();
    }

    refreshListing(): void {
        this.updatedSuccessfully.emit();
    }    

    getData() {
        let locationDataSetFieldsItem = localStorage.getItem("locationCustomFields_"+this.accountId);
        if (locationDataSetFieldsItem === null || locationDataSetFieldsItem === undefined || locationDataSetFieldsItem === 'undefined') {
            this.accountService.findAccount(this.accountId).subscribe((account) => {
           const locationDataset = account.datasets.find((dataset: any) => dataset.name === 'Locations');
                 if (locationDataset) {
                    let locationDataSetItem = localStorage.getItem("locationDataSetItem_"+this.accountId);
                    if (locationDataSetItem === null || locationDataSetItem === undefined || locationDataSetItem === 'undefined') {
                        localStorage.setItem("locationDataSetItem_"+this.accountId, JSON.stringify(locationDataset));
                    }
                     this.locationDataset = JSON.parse(locationDataSetItem);

                     this.customService.getLocationCustomScoringFields(locationDataset?.id)
                     .subscribe((data: any) => {
                       let dropDownData = data.filter(field => field.isHighCardinality === false && field.baseType !== 'DATE_TIME');

                       localStorage.setItem("locationCustomFields_"+this.accountId, JSON.stringify(dropDownData))
                       if (this.externalOverlaysFromGlobal) {
     
                       } else {
                           this.externalOverlays = dropDownData;
                       }
                     });
                 }
             });
        } else {
            if (this.externalOverlaysFromGlobal) {

            } else {
                this.externalOverlays = JSON.parse(locationDataSetFieldsItem);
            }
        }

    }
    onSelectionChange(selectedId: number) {
        const selectedData = this.externalOverlays.find(data => data.id === selectedId);
        this.selectedType = selectedData ? selectedData.type : '';
    }
    get ranges() {
        return this.createAccountForm.get('ranges') as FormArray;
    }
    
    onFieldTypeChange(event: any, isComingFromExisting = false) {
        let isUpdateValueChanged = false;
        let selectedData;
        if (this.existingData && (this.customScoringScope === CustomScoringScope.INTERNAL || this.customScoringScope === 'Locations')) {
            isUpdateValueChanged = event.value !== this.existingData?.fieldId && event.value.length <= 3;
        } else if (this.existingData && (this.customScoringScope === CustomScoringScope.EXTERNAL || this.customScoringScope === CustomScoringScope.GLOBAL)) {
            isUpdateValueChanged = event.value !== this.existingData?.fieldName;
        }
        if (this.existingData && !isUpdateValueChanged) {
            selectedData = this.externalOverlays.find(data => data.name === this.existingData.fieldName);
            this.createAccountForm.controls.fieldTextAndNumeric.setValue(selectedData.id);

        } else {
            selectedData = this.externalOverlays.find(data => data.id === event.value);
        }
        const selectedFieldType = selectedData ? selectedData.type : '';
        this.isNumberField = selectedFieldType === 'NUMBER_FIELD';
        this.isTextField = selectedFieldType === 'SHORT_TEXT_FIELD';
        this.isDropdownValueSelected = true;
        this.ranges.clear();
        this.ranges.clearValidators();
        if (this.isNumberField || this.isTextField) {
            this.ranges.setValidators([Validators.required]);
        }
        this.ranges.updateValueAndValidity();
        if (!this.isEdit || isUpdateValueChanged) {
            this.addRange();
        }
        if (this.locationDataset === undefined || this.locationDataset === null) {
            this.locationDataset = JSON.parse(localStorage.getItem("locationDataSetItem_"+this.accountId));
        }
        if (this.isEdit && !isUpdateValueChanged) {
            this.textOptions = this.dropDownValueTextOptions;
            this.changeDetector.detectChanges();
        }
        else {
        
        this.groupService.getGroupsAsTree(this.locationDataset.id, this.accountId).subscribe(groups => {
            const ids: number[] = [];
            const collectIds = (nodes: any[]) => {
              nodes.forEach(node => {
                ids.push(node.value.id);
                if (node.children && node.children.length > 0) {
                  collectIds(node.children);
                }
              });
            };
            collectIds(groups);
            let fieldId;
            if (this.customScoringScope === CustomScoringScope.EXTERNAL) {
                fieldId = this.globalDataSetRecord.datasetId + '_' + selectedData.id;
            } else {
                fieldId = selectedData.id;
            }
            this.datapointsAggregateService.getDatapointsFieldStatistics(this.locationDataset.id, fieldId, {

                datasetID: this.locationDataset.id,
                groups: ids
            })
            .subscribe((statistics) => {
                this.textOptions = statistics.values;
                this.minMaxValues = {'minValue' : 'Statistical Minimum: '+(statistics.minValue !== null ? statistics.minValue.toLocaleString() : statistics.minValue), 'maxValue':'Statistical Maximum: '+ (statistics.maxValue !== null ? statistics.maxValue.toLocaleString() : statistics.maxValue)};
                this.minValues = statistics.minValue;
                if (this.isNumberField && this.ranges.controls[0]) {
                    const currentMin = this.ranges.at(0).get('minValue').value;
                    if (!currentMin) {
                      this.ranges.at(0).patchValue({ 
                        minValue: this.minValues.toLocaleString()
                      });
                    }
                  }
                this.changeDetector.detectChanges();
            })
        });
      }
    }

    addRange() {
        const rangeGroup = this.fb.group({
            minValue: [
                '',
                this.isNumberField ? Validators.required : [],
            ],
            maxValue: [
                '',
                this.isNumberField ? Validators.required : [],
            ],
            textValues: [
                this.isTextField ? [] : null,
                this.isTextField ? Validators.required : [],
            ],
            label: ['', Validators.required],
        });
    
        this.ranges.push(rangeGroup);
      
    }
    removeRange(index: number) {
        this.ranges.removeAt(index);
    }

    drop(event: CdkDragDrop<FormGroup[]>) {
        moveItemInArray(this.ranges.controls, event.previousIndex, event.currentIndex);
        this.updatePayloadOrder();
    }
    
      updatePayloadOrder() {
        this.ranges.controls.forEach((control, index) => {
          control.patchValue({ order: index + 1 });
        });
    }

    moveRangeUp(index: number) {
        if (index === 0) return;
        const ranges = this.ranges.controls;
        [ranges[index - 1], ranges[index]] = [ranges[index], ranges[index - 1]];
        this.updatePayloadOrder();
    }
    
      moveRangeDown(index: number) {
        if (index === this.ranges.length - 1) return;
        const ranges = this.ranges.controls;
        [ranges[index], ranges[index + 1]] = [ranges[index + 1], ranges[index]];
        this.updatePayloadOrder();
    }

    get hasValidationError() {
        return this.ranges.controls.some((control) => {
          const minVal = control.get('minValue').value;
          const maxVal = control.get('maxValue').value;
          
          if (
            minVal == null ||
            maxVal == null ||
            maxVal === ''
          ) {
            return false;
          }
          
          return +minVal > +maxVal;
        });
    }
    
    initForm(): void {
        this.isFormDisabled = false;
        this.createAccountForm = this.fb.group({
            name: ['', [Validators.required]],
            fieldTextAndNumeric: ['', [Validators.required]], 
            ranges: this.fb.array([]), 

        });
        this.createAccountForm.get('ranges').clearValidators();  // CLEAR ALL VALIDATORS INITIALLY
        this.createAccountForm.updateValueAndValidity();
    }

    mapItems(items) {
        let values = [];
        Object.keys(items).map((index) => {
            let item = items[index];
            if (item) {
                values.push(index);
            }
        });
        return !(values.length === 0);
    }
    clearFormValidators(formGroup: UntypedFormGroup | FormGroup): void {
        Object.keys(formGroup.controls).forEach((key) => {
          const control = formGroup.get(key);
          if (control instanceof UntypedFormGroup || control instanceof FormGroup) {
            this.clearFormValidators(control);
          } else if (control instanceof UntypedFormControl) {
            control.clearValidators();
            control.updateValueAndValidity();
          } else {
            control.clearValidators();
            control.updateValueAndValidity();
          }
        });
      }

      
    onCancel() {
        this.onCancelClose.emit();
        this.changeDetector.detectChanges();
    }
    submitAccount(): void {
        this.createAccountForm.markAllAsTouched();
        if (this.createAccountForm.valid && !this.hasValidationError) {

            const formData = this.createAccountForm.value;
        let tempDataSetId;
        if (this.customScoringScope === CustomScoringScope.EXTERNAL) {
            tempDataSetId = this.globalDataSetRecord.datasetId;
        } else {
            tempDataSetId = this.locationDataset.id;
        }
        let datasetID = this.globalDataSetRecord ? this.globalDataSetRecord.id : '';
        let scope = this.customScoringScope;
        if (this.customScoringScope === CustomScoringScope.INTERNAL || this.customScoringScope === 'Locations') {
            datasetID = 'Locations'
            scope = 'INTERNAL';
        }
        const selectedData = this.externalOverlays.find(data => data.id === formData.fieldTextAndNumeric);
            const payload = {
                name: formData.name,
                accountId: this.accountId,
                deleted: false,
                scope: scope,
                datasetID: datasetID,
                fieldName: selectedData.name,
                fieldId: selectedData.id,
                fieldType: selectedData.type,
                fieldTextAndNumeric: formData.fieldTextAndNumeric,
                scoring: formData.ranges.map((range, index) => ({
                    minNumberValue: range.minValue.replace(/,/g, ''),
                    maxNumberValue: range.maxValue.replace(/,/g, ''),
                    textValues: range.textValues,
                    label: range.label,
                    order: index + 1,
                }))
            };
            if (this.existingData && this.existingData.length !== 0) {
                const payload = {
                    _id: this.existingData._id,
                    name: formData.name,
                    accountId: this.accountId,
                    deleted: false,
                    scope: this.existingData.scope,
                    datasetID: this.existingData.datasetID,
                    fieldName: selectedData.name,
                    fieldId: selectedData.id,
                    fieldType: selectedData.type,    
                    fieldTextAndNumeric: formData.fieldTextAndNumeric,
                    scoring: formData.ranges.map((range, index) => ({
                        minNumberValue: range.minValue.replace(/,/g, ''),
                        maxNumberValue: range.maxValue.replace(/,/g, ''),
                        textValues: range.textValues,
                        label: range.label,
                        order: index + 1,
                    }))
                };
                this.customService.updateCustomScoring(this.accountId, payload)
                .subscribe((data) => {
                    this.clearFormValidators(this.createAccountForm);
                    if (this.isEdit) {
                        this.notifService.success(
                            "Successfully updated custom scoring"
                        );
                    } else {
                        this.notifService.success(
                            "Successfully created custom scoring"
                       );
                    }
                    this.updatedSuccessfully.emit();
                    this.changeDetector.detectChanges();


                });
            } else {

                try {
                let apiError: any;
                this.customService.createCustomScoring(this.accountId, payload)
                .pipe(
                    catchError((error) => {
                        console.error("API Error: ", error.error.message);
                        apiError = error;
                        return of(null);
                    })
                )
        
                .subscribe((data) => {
                   if (apiError?.error?.errors) {
                     this.notifService.error(apiError.error.errors);
                   } else {
                    this.existingData = data; 
                    this.clearFormValidators(this.createAccountForm);
                    this.isEdit = true;
                    this.notifService.success(
                        "Successfully created custom scoring"
                    );
                    this.updatedSuccessfully.emit();
                    this.changeDetector.detectChanges();
                   }

                });
            } catch (error) {
                console.error("Unexpected Error: ", error);            
            }
        } 

          } else {
            console.log("Form is invalid. Please check the errors.");
          }
    }

    getSelectedDatasets(): string[] {
        let checkboxes = this.createAccountForm.controls.checkboxes.value;
        let datasetNames = [];
        for (const dataset in checkboxes) {
            if (checkboxes.hasOwnProperty(dataset) && checkboxes[dataset]) {
                datasetNames.push(dataset.toUpperCase());
            }
        }
        return datasetNames;
    }

}
